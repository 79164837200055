import Card from "@erecht24/frontend-kit/theme/components/cards/Card";
import CardBody from "@erecht24/frontend-kit/theme/components/cards/CardBody";
import {useContext, useEffect, useRef, useState} from "react";
import {StateContext} from "../Contexts";
import {
    PAYMENT_OPTION_CREDITCARD, PAYMENT_OPTION_FAKE,
    PAYMENT_OPTION_PAYPAL,
    PAYMENT_OPTION_SEPA, PAYMENT_OPTION_SEPA_XML
} from "../Constants";
import PaymentOption from "./PaymentOption";
import {updateAvailablePaymentMethods} from "../Billwerk/SubscriptionJS";
import SepaForm from "./SepaForm";
import Line from "@erecht24/frontend-kit/theme/components/misc/Line";
import Alert from "@erecht24/frontend-kit/theme/components/alerts/Alert";
import attention from "../../../img/attention.svg";
import CreditCardForm from "./CreditCardForm";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);
    const sepaRef = useRef(null);
    const creditCardRef = useRef(null);
    const [scrollToSepa, setScrollToSepa] = useState(false);
    const [scrollToCreditCard, setScrollToCreditCard] = useState(false);

    if (!state.availablePaymentMethods) {
        updateAvailablePaymentMethods(state);
    }

    const onSelectSepa = () => {
        setScrollToSepa(true);
    }

    const onSelectCreditCard = () => {
        setScrollToCreditCard(true);
    }

    useEffect(() => {
        if (scrollToSepa) {
            sepaRef.current.scrollIntoView({
                'behavior': 'smooth'
            });
            setScrollToSepa(false);
        } else if (scrollToCreditCard) {
            creditCardRef.current.scrollIntoView({
                'behavior': 'smooth'
            });
            setScrollToCreditCard(false);
        }
    }, [scrollToSepa, scrollToCreditCard]);

    return <Card>
        <CardBody>
            {state.contractData?.contract?.Id &&
                <Alert className="mb-6">
                    <span className="font-bold">Bitte prüfen Sie Ihre Zahlungsdaten</span> und passen Sie diese bei
                    Bedarf an.
                </Alert>
            }
            <h3 className="h3">Bezahloptionen</h3>
            {!state.contractData?.contract?.Id &&
                <p className="text-lg text-graphite mt-1">
                    Bitte wählen Sie eine der Bezahloptionen:
                </p>
            }
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10">
                {state.availablePaymentMethods?.includes(PAYMENT_OPTION_SEPA.bearer) && (
                    <PaymentOption paymentOption={PAYMENT_OPTION_SEPA} onSelect={onSelectSepa}
                                   className="order-4 md:order-1" tabIndex="0"></PaymentOption>
                )}
                {state.availablePaymentMethods?.includes(PAYMENT_OPTION_SEPA_XML.bearer) && (
                    <PaymentOption paymentOption={PAYMENT_OPTION_SEPA_XML} onSelect={onSelectSepa}
                                   className="order-4 md:order-1" tabIndex="0"></PaymentOption>
                )}
                {state.availablePaymentMethods?.includes(PAYMENT_OPTION_PAYPAL.bearer) && (
                    <PaymentOption paymentOption={PAYMENT_OPTION_PAYPAL} className="order-2"
                                   tabIndex="0"></PaymentOption>
                )}
                {state.availablePaymentMethods?.includes(PAYMENT_OPTION_CREDITCARD.bearer) && (
                    <PaymentOption paymentOption={PAYMENT_OPTION_CREDITCARD} onSelect={onSelectCreditCard}
                                   className="order-3" tabIndex="0"></PaymentOption>
                )}
                {state.mainProps.mainDataSet.testkey === '018d146f-bf24-4d3d-88e6-4eb98b92ce71' && (
                    <PaymentOption paymentOption={PAYMENT_OPTION_FAKE} className="order-5" tabIndex="0"></PaymentOption>
                )}
            </div>
            <div ref={sepaRef}
                 className={(state.paymentOption?.key === PAYMENT_OPTION_SEPA.key || state.paymentOption?.key === PAYMENT_OPTION_SEPA_XML.key) ? 'block' : 'hidden'}>
                <Line className="my-6"></Line>
                <div>
                    <SepaForm></SepaForm>
                </div>
            </div>
            <div ref={creditCardRef}
                 className={(state.paymentOption?.key === PAYMENT_OPTION_CREDITCARD.key) ? 'block' : 'hidden'}>
                <Line className="my-6"></Line>
                <div>
                    <CreditCardForm></CreditCardForm>
                </div>
            </div>
            {!state.paymentOption &&
                <div className="flex flex-row gap-1 text-red-outdated text-lg mt-4">
                    <img src={attention} alt=""/>
                    Sie müssen eine Bezahloption auswählen.
                </div>
            }
        </CardBody>
    </Card>
}
