import {useContext} from "react";
import {StateContext} from "../Contexts";
import {STEP_ADDRESS, STEP_PLAN} from "../Constants";
import ButtonPrimaryTransparent from "@erecht24/frontend-kit/theme/components/buttons/ButtonPrimaryTransparent";
import FinishOrderButton from "./FinishOrderButton";
import FinishUpgradeButton from "./FinishUpgradeButton";
import {track} from "../Util/tracking";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    if (state.step === STEP_PLAN)
        return <ButtonPrimaryTransparent onClick={() => track(
            () => state.setStep(STEP_ADDRESS),
            'checkout',
            'navigation',
            'checkout_step',
            'next'
        )} fullWidth={true} className="lg:mt-10">Weiter</ButtonPrimaryTransparent>

    if (state.cartViewData.cartType === 'initial_purchase')
        return <FinishOrderButton></FinishOrderButton>

    return <FinishUpgradeButton></FinishUpgradeButton>
}
