import React, {useContext} from "react";
import {StateContext} from "../Contexts";
import {getCurrentPlan} from "../Billwerk/SubscriptionJS";
import OnOffComponent from "./OnOffComponent";
import Line from "@erecht24/frontend-kit/theme/components/misc/Line";
import getWithComponentsArray from "../Util/getWithComponentsArray";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    if (state.mainProps.hideOnOffComponents) {
        return <></>
    }

    let plan = getCurrentPlan(state);
    let withComponents = getWithComponentsArray(state);

    if (!plan?.components || plan?.components.length < 1) {
        return <></>
    }

    const isComponentActive = (component) => {
        return state.cart.components[component.external_id]?.quantity > 0;
    }

    let components = [...Object.values(plan?.components)].filter(function (component) {
        /** @var component App_Http_Resources_Component */
        if (withComponents.length > 0 && !withComponents.includes(component.external_id))
            return false;

        return component.component_type === 'OnOff' && component.plan_components?.mode !== 'inclusive' ;
    });

    let includedComponents = [...Object.values(plan?.components)].filter(function (component) {
        /** @var component App_Http_Resources_Component */
        if (withComponents.length > 0 && !withComponents.includes(component.external_id)) {
            return false;
        }

        return component.component_type === 'OnOff' && component.plan_components?.mode === 'inclusive';
    });


    if (components.length < 1 && includedComponents.length < 1) {
        return <></>
    }

    return <>
        {includedComponents.length > 0 &&
            <div className="flex flex-col gap-10">
                {includedComponents.map(function (component) {
                    /** @var component App_Http_Resources_Component */
                    return <OnOffComponent component={component}></OnOffComponent>
                })}
            </div>
        }
        {components.length > 0 &&
            <>
                {props.withLine &&
                    <Line className="my-10"></Line>
                }
                <h3 className="h3">Runden Sie Ihren Tarif ab</h3>
                <div className="flex flex-col gap-10">
                    {components.map(function (component) {
                        /** @var component App_Http_Resources_Component */
                        return <OnOffComponent component={component} active={isComponentActive(component)}></OnOffComponent>
                    })}
                </div>
            </>
        }
    </>
}
