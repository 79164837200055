import {useContext, useState} from "react";
import {StateContext} from "../Contexts";
import {addToCart, getCurrentPlan} from "../Billwerk/SubscriptionJS";
import Alert from "@erecht24/frontend-kit/theme/components/alerts/Alert";
import Tooltip from "./Tooltip";
import {plural} from "../Util/plural";
import {trackComponent} from "../Util/tracking";
import Select from "@erecht24/frontend-kit/theme/components/selects/Select";
import Option from "@erecht24/frontend-kit/theme/components/selects/Option";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    /** @type App_Http_Resources_Component */
    let component = props.component;
    let steps = component.plan_components.steps;

    if (steps[0] !== 0) {
        steps.unshift(0);
    }

    const saveState = (newQuantity) => {
        trackComponent(() => {
            state.setCart(cart => addToCart(state, cart, {
                component: component,
                componentQuantity: newQuantity
            }));
        }, 'addEcommerceItem', component, newQuantity);
    }

    const getCurrentQuantity = () => {
        return state.cart.components[component.external_id]?.quantity ?? 0;
    }

    let components_added_by_default = 0;

    let plan = getCurrentPlan(state);

    let additionalComponentsHint = <p className="text-graphite leading-[normal] mt-1 mb-6">
        Ihr ausgewählter Tarif beinhaltet bereits <span className="font-bold">{component.plan_components?.quantity?.toLocaleString('de-DE')} {component.name}</span>.
        Wenn Sie mehr als {component.plan_components?.quantity?.toLocaleString('de-DE')} {component.name} benötigen, können Sie diese hier kostenpflichtig hinzufügen.
    </p>;

    return <div>
        {(state.cartViewData?.cartType === 'downgrade_purchase' && components_added_by_default > 0 && getCurrentQuantity() === components_added_by_default) &&
            <Alert className="mb-6">
                <strong>Ihr aktueller Tarif enthält weniger Komponenten, als Ihr bisheriger
                    Tarif.</strong> Wir haben die Komponenten nachfolgend so vorausgewählt, dass Sie
                keine Nachteile haben.
            </Alert>
        }
        <div className="flex flex-row items-center">
            <h4 className="h3">Weitere {component.name} hinzufügen</h4>
            {component.product_infotext &&
                <Tooltip>{component.product_infotext}</Tooltip>
            }
        </div>
        {additionalComponentsHint}
        <div className="flex flex-row gap-6 items-center">
            <div className="w-[160px]">
                <Select variant="outlined"
                        label="Bitte wählen"
                        value={state.cart.components[props.component.external_id]?.quantity ?? 0}
                        className="text-2xl"
                        onChange={(val) => saveState(val)}>
                    {component.plan_components.steps.map((val) => {
                        return <Option value={val}>{val.toLocaleString('de-DE')}</Option>
                    })}
                </Select>
            </div>
            <span>Weitere {component.name}</span>
        </div>
    </div>
}
