import React, {useContext} from "react";
import {StateContext} from "../Contexts";
import {getCurrentPlan} from "../Billwerk/SubscriptionJS";
import QuantityBasedComponent from "./QuantityBasedComponent";
import Card from "@erecht24/frontend-kit/theme/components/cards/Card";
import CardBody from "@erecht24/frontend-kit/theme/components/cards/CardBody";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    if (state.mainProps.hideQuantityComponents) {
        return <></>
    }

    let plan = getCurrentPlan(state);

    if (!plan?.components || plan?.components.length < 1) {
        return <></>
    }

    let components = [...Object.values(plan?.components)].filter(function (component) {
        /** @var component App_Http_Resources_Component */
        return component.component_type === 'QuantityBased' && component.plan_components.mode === 'buyable';
    });

    if (components.length < 1) {
        return <></>
    }

    return         <Card>
        <CardBody className="grid grid-cols-1 gap-10">
            {components.map(function (component) {
                /** @var component App_Http_Resources_Component */
                return <QuantityBasedComponent component={component}></QuantityBasedComponent>
            })}
        </CardBody>
    </Card>
}
