import Input from "@erecht24/frontend-kit/theme/components/inputs/Input";
import {useContext} from "react";
import {StateContext} from "../Contexts";
import {validateSepaPaymentData} from "../Util/validate";
import ValidationToolTip from "./ValidationToolTip";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    const updatePaymentData = (field, value) => {
        state.paymentData[field] = value;
        state.setPaymentData(state.paymentData);
    };

    const updatePaymentDataValidation = async () => {
        let validation = validateSepaPaymentData(state.paymentData);
        state.setPaymentDataValidation(validation);
    };

    const makeProps = (target) => {
        let value = state.paymentData[target];

        return {
            defaultValue: value,
            onChange: (event) => {
                updatePaymentData(target, event.target.value);
            },
            onBlur: (event) => {
                updatePaymentDataValidation()
            },
            error: state.paymentDataValidation.includes(target)
        };
    };

    return <>
        <h3 className="h3 mb-[3rem]">Kontodaten</h3>
        <div className="flex flex-col gap-6">
            <div className="relative">
                <Input label="Kontoinhaber *" placeholder="Max Mustermann" {...makeProps('accountHolder')}/>
                {state.paymentDataValidation.includes('accountHolder') &&
                    <ValidationToolTip>Bitte geben Sie einen Kontoinhaber an.</ValidationToolTip>
                }
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="relative">
                    <Input label="IBAN *" placeholder="DE00123456789123456789" {...makeProps('iban')}/>
                    {state.paymentDataValidation.includes('iban') &&
                        <ValidationToolTip>Bitte geben Sie eine gültige IBAN an.</ValidationToolTip>
                    }
                </div>
                <div className="relative">
                    <Input label="BIC *" placeholder="ABCDDEEF" {...makeProps('bic')}/>
                    {state.paymentDataValidation.includes('bic') &&
                        <ValidationToolTip>Bitte geben Sie eine gültige BIC an.</ValidationToolTip>
                    }
                </div>
            </div>
        </div>
    </>
}
