import Card from "@erecht24/frontend-kit/theme/components/cards/Card";
import CardBody from "@erecht24/frontend-kit/theme/components/cards/CardBody";
import ButtonWhite from "@erecht24/frontend-kit/theme/components/buttons/ButtonWhite";
import ButtonSecondary from "@erecht24/frontend-kit/theme/components/buttons/ButtonSecondary";
import {useContext} from "react";
import {StateContext} from "../Contexts";
import {addToCart} from "../Billwerk/SubscriptionJS";
import check_icon from "../../../img/check_2.svg";
import {trackComponent} from "../Util/tracking";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    /** @type App_Http_Resources_Component */
    let component = props.component;

    if (!component) {
        return <></>
    }

    const onRemove = () => {
        trackComponent(() => {
            state.setCart(cart => addToCart(state, cart, {
                component: component,
                componentQuantity: 0
            }));
        }, 'removeEcommerceItem', component, 0);
    }

    const onActivate = () => {
        trackComponent(() => {
            state.setCart(cart => addToCart(state, cart, {
                component: component,
                componentQuantity: 1
            }));
        }, 'addEcommerceItem', component, 1);
    }

    let showButtons = component.plan_components.mode !== 'inclusive' && !state.mainProps.withComponents?.includes(component.external_id);

    return <Card>
        <CardBody>
            <div className="flex flex-col gap-10">
                <div className="flex flex-row gap-5 items-start">
                    <div className="flex flex-row min-h-[47px] items-center">
                    {component.svg_icon && <div dangerouslySetInnerHTML={{__html: component.svg_icon}}></div>}
                    </div>
                    <div className={(!component.svg_icon ? 'ml-[60px] ' : '') + `flex flex-col gap-4`}>
                        <div className="flex flex-row justify-between items-center md:items-start gap-5 md:gap-2">
                            <div className="flex flex-col shrink">
                                <div className="flex flex-row min-h-[47px] items-center">
                                    <h4 className="h3 text-left">{component.name}</h4>
                                </div>
                                {component.plan_components.mode !== 'inclusive' &&
                                    <p className="text-graphite font-bold">{component.price_per_unit}&euro;/mtl</p>
                                }
                                {component.plan_components.mode === 'inclusive' &&
                                    <p>
                                        <span className="text-blue font-bold mr-2">0&euro;/mtl</span>
                                        <span className="text-gray-dark strikethrough-price">{component.price_per_unit}&euro;/mtl</span>
                                    </p>
                                }
                            </div>
                            {showButtons &&
                                <>
                                    {props.active &&
                                        <ButtonWhite className="hidden lg:block min-w-[160px] max-w-[160px] md:ml-auto" onClick={onRemove}>entfernen</ButtonWhite>
                                    }
                                    {!props.active &&
                                        <ButtonSecondary className="hidden lg:block min-w-[160px] max-w-[160px] md:ml-auto" onClick={onActivate}>hinzufügen</ButtonSecondary>
                                    }
                                </>
                            }
                            {component.plan_components.mode === 'inclusive' &&
                                <>
                                    <div className="hidden lg:flex flex-row gap-2 items-center h-[47px]">
                                        <div className="text-lg font-black text-blue uppercase">inklusive</div>
                                        <img src={check_icon} alt="" className="h-4"/>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="text-graphite leading-[normal]"
                             dangerouslySetInnerHTML={{__html: component.product_infotext}}></div>
                    </div>
                </div>
                {showButtons &&
                    <>
                        {props.active &&
                            <ButtonWhite className="block lg:hidden" onClick={onRemove}>entfernen</ButtonWhite>
                        }
                        {!props.active &&
                            <ButtonSecondary className="block lg:hidden" onClick={onActivate}>hinzufügen</ButtonSecondary>
                        }
                    </>
                }
                {component.plan_components.mode === 'inclusive' &&
                    <>
                        <div className="flex lg:hidden flex-row gap-2 justify-center items-center">
                            <div className="text-lg font-black text-blue uppercase">inklusive</div>
                            <img src={check_icon} alt="" className="h-4"/>
                        </div>
                    </>
                }
            </div>
        </CardBody>
    </Card>
}
