import {useContext, useState} from "react";
import {StateContext} from "../Contexts";
import ButtonPrimaryTransparent from "@erecht24/frontend-kit/theme/components/buttons/ButtonPrimaryTransparent";
import {finalizePaymentData, getPaymentConfig, makeOrderPayload} from "../Billwerk/SubscriptionJS";
import validate, {validateCreditCardPaymentData, validateSepaPaymentData} from "../Util/validate";
import handleError from "../Billwerk/handleError";
import LegalText from "./LegalText";
import {track} from "../Util/tracking";
import secureIcon from "../../../img/secure.svg";
import {
    PAYMENT_OPTION_CREDITCARD,
    PAYMENT_OPTION_PAYPAL,
    PAYMENT_OPTION_SEPA,
    PAYMENT_OPTION_SEPA_XML
} from "../Constants";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);
    const [processing, setProcessing] = useState(false);

    const onOrderClick = async () => {
        let validationFailed = false;
        let validation = await validate(state.customerData);

        if (validation.length > 0) {
            state.setCustomerDataValidation(validation);
            validationFailed = true;
        }

        sessionStorage?.setItem('checkout_payment_started', state.paymentOption?.key);

        if (state.paymentOption?.key === PAYMENT_OPTION_SEPA.key || state.paymentOption?.key === PAYMENT_OPTION_SEPA_XML.key) {
            let sepaValidation = validateSepaPaymentData(state.paymentData, true);

            if (sepaValidation.length > 0) {
                state.setPaymentDataValidation(sepaValidation);
                validationFailed = true;
            }
        } else if (state.paymentOption?.key === PAYMENT_OPTION_CREDITCARD.key) {
            let ccValidation = validateCreditCardPaymentData(state.paymentData, true);

            if (ccValidation.length > 0) {
                state.setPaymentDataValidation(ccValidation);
                validationFailed = true;
            }
        }

        if (validationFailed) {
            setProcessing(false);
            return;
        }

        let signupService = new SubscriptionJS.Signup();
        let paymentService = new SubscriptionJS.Payment(getPaymentConfig(state.mainProps.entityId, state.returnUrl),
            () => {
                createOrder();
            },
            (error) => {
                console.log(error);
                createOrder();
            }
        );

        const createOrder = function () {
            let cart = makeOrderPayload(
                state,
                state.customerData,
                null,
                null
            );

            fetch('//' + window.location.host + '/orders', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(cart)
            })
                .then((response) => {
                    if (!response.ok) {
                        return Promise.reject(response);
                    }

                    return response.json();
                }, errorHandler)
                .then((data) => pay(data), errorHandler);
        };

        const pay = function (order) {
            let paymentData = {
                bearer: state.paymentOption.bearer,
                currency: "EUR"
            };

            if (state.paymentOption.key === PAYMENT_OPTION_SEPA.key || state.paymentOption.key === PAYMENT_OPTION_SEPA_XML.key || state.paymentOption.key === PAYMENT_OPTION_CREDITCARD.key) {
                Object.assign(paymentData, finalizePaymentData(state.paymentData));
            }

            signupService.paySignupInteractive(paymentService, paymentData, {
                OrderId: order.order.Id,
                GrossTotal: order.order.TotalGross,
                Currency: order.order.Currency
            }, paymentHandler, errorHandler);
        };

        const paymentHandler = function (result) {
            if (result.Url) {
                window.location.href = result.Url;
            } else {
                let returnUrl = new URL(state.returnUrl);
                returnUrl.searchParams.set('order_id', result.OrderId);
                window.location.href = returnUrl.href;
            }
        };

        const errorCallback = () => {
            setProcessing(false)
        };

        const errorHandler = function (errorData) {
            if (typeof errorData.json === 'function') {
                /**
                 * errorData is a failed Response object
                 * Try to parse json from the response and pass the result object to handleError
                 */
                errorData.json().then((data) => {
                    handleError(data, state, errorCallback);
                }, () => {
                    /**
                     * Parsing json from response failed, call handleError with a generic errorCode
                     */
                    handleError({
                        errorCode: ["UnmappedError"]
                    }, state, errorCallback);
                })
            } else {
                /**
                 * errorHandler was called with a proper errorData object, pass it to handleError
                 */
                handleError(errorData, state, errorCallback);
            }
        };
    };

    if (processing) {
        track(
            onOrderClick,
            'checkout',
            'submit_order',
            'order_now'
        );
    }

    return <>
        <LegalText></LegalText>
        <div className="text-center">
            <ButtonPrimaryTransparent onClick={() => setProcessing(true)}
                fullWidth={true}
                disabled={!state.paymentOption || processing}>
                {processing &&
                    <>...</>
                }
                {!processing &&
                    <>Jetzt kostenpflichtig bestellen</>
                }
            </ButtonPrimaryTransparent>
            <div className="flex flex-row justify-center gap-2 mt-2 text-base text-blue font-medium">
                <img src={secureIcon} alt="" className="h-[18px]"/>
                <span>30 Tage Geld-Zurück-Garantie</span>
            </div>
            {(state.paymentOption?.key === PAYMENT_OPTION_PAYPAL.key) && (
                <p className="text-base text-graphite mt-3">Sie werden zur Bezahlung weitergeleitet.</p>
            )}
        </div>
    </>
}
