import React from "react";
import {createRoot} from "react-dom/client";
import * as Sentry from "@sentry/react";
import Main from './Components/Main';
import {track} from "./Util/tracking";

class Checkout extends HTMLElement {
    constructor() {
        super();

        // @hpilz Attribute sind im dataset
        // https://git.muensmedia.de/erecht24/zahlungsmanagement/-/wikis/Testing#get-parameter-f%C3%BCr-den-checkout

        this.shadow = this.attachShadow({mode: 'open'});
        this.widgetRoot = document.createElement('div');
        this.entityId = document.querySelector('[data-checkout]').dataset.entityId;
        this.returnUrl = document.querySelector('[data-checkout]').dataset.returnUrl;

        this.stylesLoaded = false;
        const styles = document.createElement('link');
        styles.setAttribute('rel', 'stylesheet');
        styles.setAttribute('href', document.querySelector('[data-checkout]').dataset.css);
        this.shadow.appendChild(styles);

        styles.onload = (() => {
            this.stylesLoaded = true;
            this.showWidget();
        });

        this.subscriptionJsLoaded = false;
        const subscriptionJs = document.createElement('script');
        subscriptionJs.setAttribute('src', (document.querySelector('[data-checkout]').dataset.env === 'production')
            ? 'https://selfservice.billwerk.com/subscription.js'
            : 'https://selfservice.sandbox.billwerk.com/subscription.js'
        );
        this.shadow.appendChild(subscriptionJs);

        subscriptionJs.onload = (() => {
            this.subscriptionJsLoaded = true;
            this.showWidget();
        });
    }

    showWidget() {
        if (!this.stylesLoaded || !this.subscriptionJsLoaded) {
            return;
        }

        if (window.sessionStorage?.getItem('checkout_payment_started')) {
            track(
                () => {
                    window.sessionStorage?.removeItem('checkout_payment_started');
                    let url = new URL(window.location.href);
                    url.search = window.sessionStorage?.getItem('query_params') ?? '';
                    window.location.href = url.toString();
                },
                'checkout',
                'payment_status',
                'payment_failed',
                window.sessionStorage?.getItem('checkout_payment_started') ?? true
            );
        } else {
            // If query parameters for the checkout change, clear session storage
            // do this only when 'checkout_payment_started' was not set (user has not started payment process)
            // when the user returns e.g. from the credit card payment provider page, it is not guaranteed that the URL parameters are kept
            // therefore we don't clear the sessionStorage in this case
            const currentQueryParams = window.location.search;
            const previousQueryParams = window.sessionStorage?.getItem('query_params') ?? null;

            if (previousQueryParams !== null && currentQueryParams !== previousQueryParams) {
                window.sessionStorage?.clear();
            }

            // Write current query parameters to session storage
            window.sessionStorage?.setItem('query_params', currentQueryParams);
            window.sessionStorage?.setItem('entry_url', window.location.href);
        }

        const root= createRoot(this.widgetRoot)
        root.render(
            <Main returnUrl={this.returnUrl}
                  entityId={this.entityId}
                  product={this.dataset.product ?? null}
                  variantLocked={this.dataset.variantLocked ?? null}
                  showVoucher={parseInt(this.dataset.showVoucher ?? 0) === 1}
                  voucher={this.dataset.voucher ?? null}
                  hidePlans={parseInt(this.dataset.hidePlans ?? 0) === 1}
                  hideQuantityComponents={parseInt(this.dataset.hideQuantityComponents ?? 0) === 1}
                  hideOnOffComponents={parseInt(this.dataset.hideOnoffComponents ?? 0) === 1}
                  withComponents={this.dataset.withComponents ?? null}
                  erecht24UserUuid={this.dataset.erecht24UserUuid ?? null}
                  hideFooter={this.dataset.hideFooter ?? false}
                  checkoutVersion={this.dataset.checkoutVersion ?? null}
                  mainDataSet={this.dataset}
                  />
        );

        this.shadow.appendChild(this.widgetRoot);
    }
}

Sentry.init({
    dsn: "https://b1a97b3ab821cc68328ba031a2bb7014@sentry.e-recht24.dev/3",
    environment: document.querySelector('[data-checkout]').dataset.env,
    enabled: document.querySelector('[data-checkout]').dataset.env !== 'local'
});

customElements.define('checkout-main', Checkout);
