import {getPlanVariantByExternalId, getPlanVariantPeriod} from "../Billwerk/SubscriptionJS";
import {PERIOD_ANNUALLY, PERIOD_ANNUALLY_MONTHLY_PAID} from "../Constants";

/**
 * @param state
 * @returns {boolean}
 */
export default function (state) {
    let preselectedPlanVariant = getPlanVariantByExternalId(state, state.mainProps.variantLocked || state.mainProps.product);
    return preselectedPlanVariant && [PERIOD_ANNUALLY, PERIOD_ANNUALLY_MONTHLY_PAID].includes(getPlanVariantPeriod(preselectedPlanVariant, false));
}
