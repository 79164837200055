import {PERIOD_ANNUALLY, PERIOD_ANNUALLY_MONTHLY_PAID, PERIOD_MONTHLY} from "../Constants";
import PlanVariantButton from "./PlanVariantButton";
import PaymentVariantButton from "./PaymentVariantButton";
import {useContext} from "react";
import {StateContext} from "../Contexts";
import {getPlanVariantForPeriod} from "../Billwerk/SubscriptionJS";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    let planVariantButtons = <>
        <PlanVariantButton period={PERIOD_MONTHLY} periodLabel="Monatlich" active={state.period === PERIOD_MONTHLY}></PlanVariantButton>
        <PlanVariantButton period={PERIOD_ANNUALLY} periodLabel="Jährlich" active={state.period === PERIOD_ANNUALLY || state.period === PERIOD_ANNUALLY_MONTHLY_PAID}></PlanVariantButton>
    </>

    if (state.mainProps.checkoutVersion === 'b') {
        planVariantButtons = <>
            <PlanVariantButton period={PERIOD_ANNUALLY_MONTHLY_PAID} periodLabel="Jährlich"
                               active={state.period === PERIOD_ANNUALLY || state.period === PERIOD_ANNUALLY_MONTHLY_PAID}></PlanVariantButton>
            <PlanVariantButton period={PERIOD_MONTHLY} periodLabel="Monatlich"
                               active={state.period === PERIOD_MONTHLY}></PlanVariantButton>
        </>
    }


    return <div className="grid grid-cols-1 gap-6">
        {state.mainProps.variantLocked === null &&
            <p className="text-xl text-center xl:text-left text-blue-dark font-medium leading-[1.375rem]">Laufzeit auswählen</p>
        }
        <div className="grid grid-cols-1 gap-6 xl:grid-cols-2 xl:gap-12">
            {planVariantButtons}
        </div>
        {getPlanVariantForPeriod(state, PERIOD_ANNUALLY_MONTHLY_PAID) &&
            <PaymentVariantButton disabled={state.period !== PERIOD_ANNUALLY && state.period !== PERIOD_ANNUALLY_MONTHLY_PAID}></PaymentVariantButton>
        }
    </div>
}
