import {useContext} from "react";
import {StateContext} from "../Contexts";
import check_icon from "../../../img/check_2.svg";
import {
    addToCart,
    getMonthlyPriceForPlanVariant,
    getPlanVariantForPeriod, getPlanVariantPeriod
} from "../Billwerk/SubscriptionJS";
import {PERIOD_ANNUALLY, PERIOD_ANNUALLY_MONTHLY_PAID, PERIOD_MONTHLY} from "../Constants";
import formatCurrency from "../Util/formatCurrency";

export default function ({period, periodLabel, active}) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    let planVariantForPeriod = getPlanVariantForPeriod(state, period);

    const updateState = () => {
        // the button for period_annually should do nothing when monthly payment for annual subscription is selected
        if (period === PERIOD_ANNUALLY && state.period === PERIOD_ANNUALLY_MONTHLY_PAID) {
            return;
        }

        state.setPeriod(period);

        state.setCart(cart => addToCart(state, cart, {
            PlanVariantId: planVariantForPeriod.id
        }));
    };

    if (state.mainProps.variantLocked && (
        !planVariantForPeriod
        || getPlanVariantPeriod(planVariantForPeriod, false) !== state.period
    )) {
        return <></>
    }

    return <button onClick={updateState}
                   className={(active ? `active` : ``) + ` text-left p-4 tile relative focus:ring`}>
        {planVariantForPeriod?.sale_badge &&
            <div className="fifty-percent-off-label">{planVariantForPeriod.sale_badge}</div>
        }
        <span className="block text-xl font-medium leading-[normal]">{periodLabel}</span>
        <span className={(planVariantForPeriod === null ? 'skeleton' : '') + ` text-3xl font-bold`}>{planVariantForPeriod === null ? <>?? &euro;</> : formatCurrency(getMonthlyPriceForPlanVariant(planVariantForPeriod), true)}</span>
        <span className="text-gray-dark text-lg">/mtl</span>
        {active && <img src={check_icon} alt="" className="absolute top-4 right-4" />}
    </button>
}
