import info_icon from "../../../img/info.svg";

export default function (props) {
    return <div className="tooltip">
        <img src={info_icon} alt={props.alt ?? "Weitere Informationen"} className="!w-[18px] !h-[18px]"/>
        <div className="tooltip-content">
            {(typeof props.children !== 'string') && props.children}
            {(typeof props.children === 'string') && <div dangerouslySetInnerHTML={{__html: props.children}}/>}
            <i></i>
        </div>
    </div>
}
