import formatCurrency from "../Util/formatCurrency";
import {getMonthlyPriceForPlanVariant, getPlanVariantForPeriod} from "../Billwerk/SubscriptionJS";
import {PERIOD_ANNUALLY} from "../Constants";
import {useContext} from "react";
import {StateContext} from "../Contexts";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);
    let paymentInfo = null;
    let priceText = null;
    let strikethroughPriceText = null;
    let altPriceHint = null;

    if (props.plan.contractPeriodUnit === 'Year') {
        if (props.plan.feePeriodUnit === 'Year') {
            paymentInfo = 'mit Jahreszahlung';
            priceText = '12 Monate à ' + formatCurrency(props.plan.monthlyPrice);

            if (props.strikethroughPrice) {
                strikethroughPriceText = 'bei Monatstarif ' + formatCurrency(props.strikethroughPrice / 12.);
            }
        } else if (props.plan.feePeriodUnit === 'Month' && props.plan.feePeriodQuantity === 1) {
            paymentInfo = 'mit Monatszahlung';
            priceText = 'mtl. ' + formatCurrency(props.plan.monthlyPrice);

            if (props.strikethroughPrice) {
                strikethroughPriceText = 'bei Monatstarif ' + formatCurrency(props.strikethroughPrice / 12.);
            }
        }
    } else if (props.plan.feePeriodUnit === 'Month' && props.plan.feePeriodQuantity === 1) {
        paymentInfo = 'mit Monatszahlung';
        priceText = props.plan.feePeriodQuantity + ' Monat' + (props.plan.feePeriodQuantity !== 1 ? 'e' : '') + ' à ' + formatCurrency(props.plan.monthlyPrice);

        if (state.mainProps.checkoutVersion === 'b') {
            altPriceHint = 'im Jahrestarif ' + formatCurrency(getMonthlyPriceForPlanVariant(getPlanVariantForPeriod(state, PERIOD_ANNUALLY)));
        }
    }

    return <div className="flex flex-row justify-between items-start">
       <div className="flex flex-col gap-2 items-start max-w-[50%]">
           <p className="text-xl text-blue-dark font-bold text-balance">{props.plan.title}</p>
           {paymentInfo && props.plan.monthlyPrice > 0 && state.mainProps.checkoutVersion !== 'b' &&
               <p className="text-base lg:text-lg text-graphite">{paymentInfo}</p>
           }
       </div>
       <div className="flex flex-col text-right text-xl text-blue-dark items-end">
           <span className="font-bold">
               {priceText}
           </span>
           {strikethroughPriceText &&
               <span className={state.mainProps.checkoutVersion === 'b' ? `strikethrough-price-straight-text-color` : `strikethrough-price-straight`}>
                   statt {strikethroughPriceText}
               </span>
           }
           {altPriceHint &&
               <span className="text-lg">
                   statt {altPriceHint}
               </span>
           }
       </div>
    </div>

}
