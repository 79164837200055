import {StateContext} from "../Contexts";
import {useContext} from "react";
import {validateCreditCardPaymentData, validateSepaPaymentData} from "../Util/validate";
import {track} from "../Util/tracking";
import {PAYMENT_OPTION_CREDITCARD, PAYMENT_OPTION_SEPA, PAYMENT_OPTION_SEPA_XML} from "../Constants";
import {defaultCeditCardPaymentData, defaultSepaPaymentData} from "../Billwerk/PaymentData";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    const updatePaymentOption = () => {
        state.setPaymentOption(props.paymentOption);
        let validation = [];

        if (props.paymentOption.key === PAYMENT_OPTION_SEPA.key || props.paymentOption.key === PAYMENT_OPTION_SEPA_XML.key) {
            if (!state.paymentData.hasOwnProperty('accountHolder')) {
                state.setPaymentData(defaultSepaPaymentData);
            } else {
                validation = validateSepaPaymentData(state.paymentData);
            }
        } else if (props.paymentOption.key === PAYMENT_OPTION_CREDITCARD.key) {
            if (!state.paymentData.hasOwnProperty('cardHolder')) {
                state.setPaymentData(defaultCeditCardPaymentData);
            } else {
                validation = validateCreditCardPaymentData(state.paymentData);
            }
        }

        state.setPaymentDataValidation(validation);

        if (props.onSelect) {
            props.onSelect();
        }

        let trackEventValue = props.paymentOption.key;

        if (trackEventValue === PAYMENT_OPTION_CREDITCARD.key) {
            trackEventValue = 'credit_card';
        }

        track(null, 'checkout', 'payment_selection', 'payment_provider', trackEventValue);
    }

    return <div
        {...((props.tabIndex ?? false) ? {tabIndex: props.tabIndex} : {})}
        className={(state.paymentOption?.key === props.paymentOption.key ? `active` : ``) + ` tile p-6 flex flex-col items-center justify-center text-lg font-bold focus-visible:ring ${props.className ?? ''}`}
        onClick={updatePaymentOption}
        onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') updatePaymentOption(); e.stopPropagation() }}>
        {props.paymentOption.icon &&
            <img src={props.paymentOption.icon} className="mb-4 h-[48px]" alt=""/>
        }
        {props.paymentOption.iconComponent &&
            <>{props.paymentOption.iconComponent}</>
        }
        <p className="text-center">{props.paymentOption.label}</p>
    </div>
}
