import Card from "@erecht24/frontend-kit/theme/components/cards/Card";
import CardBody from "@erecht24/frontend-kit/theme/components/cards/CardBody";
import PlanVariant from "./PlanVariant";
import {StateContext} from "../Contexts";
import {useContext} from "react";
import ButtonWhite from "@erecht24/frontend-kit/theme/components/buttons/ButtonWhite";
import {getCurrentPlan, getCurrentPlanVariant} from "../Billwerk/SubscriptionJS";
import {track} from "../Util/tracking";
import {PERIOD_ANNUALLY, PERIOD_ANNUALLY_MONTHLY_PAID, PERIOD_MONTHLY} from "../Constants";
import jahrestarif_siegel from "../../../img/jahrestarif-siegel.svg";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    let plan = getCurrentPlan(state);
    let planVariant = getCurrentPlanVariant(state);

    if (planVariant?.recurring_fee < 0.01)
        return <></>

    return <div className="flex flex-col gap-[59px]">
        <Card>
            <CardBody>
                <div className="flex items-start self-stretch justify-center lg:justify-between mb-10 gap-6">
                    <div className="flex flex-1 flex-col items-center lg:items-start justify-start gap-2">
                        {state.cartViewData && (
                            <>
                                {planVariant?.name_override && <h3 className="h3 text-center lg:text-left text-balance lg:text-wrap">{planVariant?.name_override}</h3>}
                                {(!planVariant?.name_override && plan?.name) && <h3 className="h3 text-center lg:text-left text-balance lg:text-wrap">{plan.name}</h3>}
                                {(!planVariant?.name_override && !plan?.name) &&
                                    <h3 className="h3 text-center lg:text-left text-balance lg:text-wrap skeleton w-full">&nbsp;</h3>}
                                <div className="flex flex-row self-stretch justify-evenly lg:justify-between">
                                    <button className="text-blue text-lg underline hover:text-gray-very-dark"
                                            onClick={() => track(
                                                () => state.setPlanDetailsOpen(!state.planDetailsOpen),
                                                'checkout',
                                                'check_tariff_details',
                                                'check_details'
                                            )}
                                    >Details
                                    </button>
                                    {(!state.mainProps.hidePlans && state.mainProps.checkoutVersion !== 'b') &&
                                        <button
                                            className="text-blue text-lg underline lg:hidden hover:text-gray-very-dark"
                                            onClick={() => state.setPlanDialogOpen(!state.planDialogOpen)}
                                        >Tarif wechseln
                                        </button>
                                    }
                                </div>
                            </>
                        )}
                        {!state.cartViewData && (
                            <>
                                <h3 className="h3 h-[30px] w-[150px] skeleton"></h3>
                                <button className="text-lg skeleton w-[50px] h-[24px]"></button>
                            </>
                        )}
                    </div>
                    {(!state.mainProps.hidePlans && state.mainProps.checkoutVersion !== 'b') &&
                        <ButtonWhite disabled={state.cartViewData === null}
                                     className="hidden lg:block"
                                     onClick={() => {
                                         state.setPlanDialogOpen(!state.planDialogOpen);
                                     }}>Tarif wechseln</ButtonWhite>
                    }
                    {(!state.mainProps.mainDataSet.hasOwnProperty('hidePlans') && state.mainProps.checkoutVersion === 'b') &&
                        <div className="text-blue text-lg">
                            {(state.period === PERIOD_ANNUALLY || state.period === PERIOD_ANNUALLY_MONTHLY_PAID) &&
                                <span>Jahrestarif</span>
                            }
                            {state.period === PERIOD_MONTHLY &&
                                <span>Monatstarif</span>
                            }
                        </div>
                    }
                </div>
                <PlanVariant></PlanVariant>
            </CardBody>
        </Card>
        {plan?.custom_fields?.ProductBenefits && state.mainProps.checkoutVersion === 'b' &&
            <Card className="tile active">
                <CardBody className="pr-[1.5rem] md:pr-[150px]">
                    <div className="plan-details--list annual-plan" dangerouslySetInnerHTML={{__html: plan?.custom_fields?.ProductBenefits}}>
                    </div>
                    <img className="absolute hidden md:inline top-0 right-10" src={jahrestarif_siegel} alt="25 Jahre Expertise"/>
                </CardBody>
            </Card>
        }
    </div>
}
