import PaymentOptionData from "../Util/PaymentOptionData";
import {PAYMENT_OPTION_CREDITCARD} from "../Constants";

export const defaultSepaPaymentData = {
    "accountHolder" : "",
    "iban" : "",
    "bic" : ""
};

export const defaultCeditCardPaymentData = {
    "cardHolder" : "",
    "cardNumber" : "",
    "expiryMonth" : "",
    "expiryYear" : "",
    "cvc" : ""
};

export default function () {
    let storedPaymentData = sessionStorage?.getItem('checkout_payment_data');

    if (null === storedPaymentData) {
        return defaultSepaPaymentData;
    }

    storedPaymentData = JSON.parse(storedPaymentData);
    let isValid = true;

    if (PaymentOptionData()?.key === PAYMENT_OPTION_CREDITCARD.key) {
        Object.keys(defaultCeditCardPaymentData).forEach(function (key) {
            if (!storedPaymentData.hasOwnProperty(key)) {
                isValid = false;
            }
        });

        if (!isValid) {
            return defaultCeditCardPaymentData;
        }
    } else {
        Object.keys(defaultSepaPaymentData).forEach(function (key) {
            if (!storedPaymentData.hasOwnProperty(key)) {
                isValid = false;
            }
        });

        if (!isValid) {
            return defaultSepaPaymentData;
        }
    }

    return storedPaymentData;
}
