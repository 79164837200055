import PricingTable from "./PricingTable";
import FeatureSection from "./FeatureSection";
import check_icon from "../../img/check-icon-feature-list.svg";

export default function (props) {
    return <PricingTable title="Webseitenbetreiber">
        <FeatureSection title="Website absichern">
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Webseiten absichern,
                    anlegen &amp; verwalten inklusive
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Legen Sie Webseiten an und speichern, verwalten und bearbeiten
                            Sie diese jederzeit (mehr jederzeit zubuchbar).<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    1
                    <div className="small-info-text">(mehr jederzeit zubuchbar)</div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    2
                    <div className="small-info-text">(mehr jederzeit zubuchbar)</div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    5
                    <div className="small-info-text">(mehr jederzeit zubuchbar)</div>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Cookie Consent Tool von
                    Usercentrics mit mtl. Sessions pro Webseite inklusive
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erstellen Sie rechtskonforme Cookie Banner für Ihre Webseiten
                            mit dem Usercentrics Tool (mehr zeitnah zubuchbar). Sessions definiert Usercentrics als
                            Seitenaufrufe pro Monat.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">5.000</div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">40.000</div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">625.000 <div className="small-info-text">(max. 125.000
                    pro Projekt)</div></div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Website Scanner Premium für Ihre
                    Webseiten
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Scannen Sie Ihre Webseiten automatisch auf Datenschutzverstöße
                            und Fehler und erhalten Sie praktische Handlungsempfehlungen und direkte
                            Umsetzungshilfen.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Datenschutz Generator
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erstellen Sie DSGVO-konforme Datenschutzerklärungen für Ihre
                            Webseiten und verwalten und aktualisieren Sie diese jederzeit - auch auf Englisch.<i></i>
                        </div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Impressum Generator
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erstellen Sie ein rechtssicheres Impressum für Ihre Webseiten
                            und verwalten und aktualisieren Sie diese jederzeit - auch auf Englisch.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Google Fonts Scanner
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Prüfen Sie Ihre Webseiten innerhalb weniger Sekunden auf eine
                            korrekte Einbindung von Google Fonts.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Updateservice
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erhalten Sie Benachrichtigungen, wenn Sie Ihre Rechtstexte
                            aufgrund neuer Urteile, Abmahnwellen oder weiterer Ergänzungen aktualisieren müssen.<i></i>
                        </div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Projekt Manager
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Verwalten Sie alle Ihre erstellten Rechtstexte zentral an einem
                            Ort und sehen Sie den Status Ihrer Rechtssicherheit jederzeit.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Projekt Planer
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Gehen Sie Schritt für Schritt durch die wichtigsten Phasen der
                            Planung und Umsetzung einer rechtssicheren Webseite und erhalten Sie praktische Tools und
                            Handlungsempfehlungen.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Projekt Report
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Dokumentieren Sie den Fortschritt in der Absicherung Ihrer
                            Webseiten mit Notizen und Zeitangabe.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Rechtstexte Plugin für Ihr CMS
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Für Wordpress, Joomla, Typo3, Contao, HTML/php verfügbar:
                            Integrieren Sie mit nur eine Klick Ihre erstellten Rechtstexte in Ihre Webseiten.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
        </FeatureSection>
        <FeatureSection title="AGB-, Vertragsgeneratoren &amp; Muster nutzen">
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">AGB inkl. Updateservice für
                    Webseitenbetreiber und <span className="fix">Unternehmer
        <div className="tooltip"><img decoding="async"
                                      src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
          <div className="tooltip-content tooltip-right">Erstellen Sie schnell und einfach Ihre professionellen AGB. Immer aktuell dank Updateservice.<i></i></div>
        </div></span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4"></div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Generator für
                    Auftragsverarbeitung<br/> (AV-Vertrag)
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erstellen Sie schnell und einfach notwendige AV-Verträge. Immer
                            aktuell dank Updateservice<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Vertragsschluss <span
                    className="release-info">(Coming soon)</span>
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Schließen Sie online rechtssichere Verträge mit Ihren Kunden
                            und Vertragspartnern ab.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Vertragsschluss mit
                    Online-Unterschrift <span className="release-info">(Coming soon)</span>
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Schließen Sie online rechtssichere Verträge mit Ihren Kunden
                            und Vertragspartnern ab. Inklusive digitaler Signatur. <i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Abmahnschutz
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Lehnen Sie sich entspannt zurück. Für alle eRecht24 Rechtstexte
                            sind Sie vor teuren Abmahnungen geschützt. <i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Kostenlose anwaltliche <span
                    className="fix">Erstberatung
        <div className="tooltip"><img decoding="async"
                                      src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
          <div className="tooltip-content tooltip-right">Wenden Sie sich bei rechtlichen Fragen kostenlos an die Erstberatung unserer Partner Kanzlei Siebert Lexow Lang.<i></i></div>
        </div></span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Individuelle anwaltliche Prüfung
                    Ihres Unternehmens
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Sie haben Fragen zu Ihrem Geschäftsmodell, Ihren Rechtstexten,
                            Marketingmaßnahmen oder zum Datenschutz? Inklusive im Enterprise Tarif erhalten Sie eine
                            kostenlose anwaltliche Beratungsstunde pro Jahr – durchgeführt und erbracht von unserer
                            Partnerkanzlei Siebert Lexow Lang im Wert 350 EUR.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    1 Stunde
                    <div className="small-info-text">(Im Wert von 350,00 EUR)</div>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Vertragsmuster
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Nutzen Sie praktische und rechtssichere Muster, Verträge und
                            Vorlagen. Direkt als Download verfügbar. <i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    10
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    alle
                </div>
            </div>
        </FeatureSection>
        <FeatureSection title="Online-Shop absichern">
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4 grey">Shop-AGB
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erhalten Sie Shop-spezifische AGB und Widerrufsbelehrungen für
                            Ihre Shop-Präsenzen sowie Benachrichtigungen, wenn diese angepasst werden müssen.<i></i>
                        </div>
                    </div>
                </div>
                <div className="feature-section--row--overlay">Shop-AGB und Widerruf sind nur in den Tarifen für <a
                    href="?tarif=shop">Shop-Betreiber</a> erhältlich.
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4 grey">Widerrufsbelehrung
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erhalten Sie Shop-spezifische AGB und Widerrufsbelehrungen für
                            Ihre Shop-Präsenzen sowie Benachrichtigungen, wenn diese angepasst werden müssen.<i></i>
                        </div>
                    </div>
                </div>
            </div>
        </FeatureSection>
        <FeatureSection title="Datenschutz-Management betreiben">
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Datenschutz-Lösung für Ihr
                    Unternehmen
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Behalten Sie das Thema Datenschutz in Ihrem Unternehmen im
                            Blick und managen Sie alle notwendigen To-Do's übersichtlich und zentral an einer
                            Stelle.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    nicht inklusive
                    <div className="small-info-text">(kann einzeln dazugekauft werden)</div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    nicht inklusive
                    <div className="small-info-text">(kann einzeln dazugekauft werden)</div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Notfallbutton mit Stellungnahme
                    zum nächsten Werktag
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erhalten Sie bei einer Datenschutz-Panne kostenfrei
                            professionelle anwaltliche Hilfe am nächsten Werktag (durch die Kanzlei Siebert
                            Lexow).<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
        </FeatureSection>
        <FeatureSection title="Marktplatz für digitales Business">
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Profi-Eintrag im Marktplatz
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erhalten Sie Zugang zu dem eRecht24 Marktplatz für digitales
                            Business mit einem umfangreichen Profi-Eintrag: Für mehr Reichweite, mehr Kunden und mehr
                            Erfolg für Ihr Business.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
        </FeatureSection>
        <FeatureSection title="Social Media Marketing und Profile absichern">
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Social Media Datenschutz <span
                    className="fix">Generator
        <div className="tooltip"><img decoding="async"
                                      src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
          <div className="tooltip-content tooltip-right">Erstellen Sie in wenigen Schritten rechtskonforme Datenschutzerklärungen für Ihre Profile in sozialen Netzwerken und verwalten und aktualisieren Sie diese jederzeit.<i></i></div>
        </div></span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Social Media Impressum Generator
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erstellen Sie ein angepasstes Impressum für Ihre Profile in
                            sozialen Netzwerken und verwalten und aktualisieren Sie dieses jederzeit.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Social Media Safe Sharing Tool
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Integrieren und nutzen Sie rechtssichere Social Media Sharing
                            Funktionen auf Ihren Webseiten.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
        </FeatureSection>
        <FeatureSection title="Newsletter, Marketing und Business absichern">
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">E-Mail Signatur Datenschutz <span
                    className="fix">Generator
        <div className="tooltip"><img decoding="async"
                                      src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
          <div className="tooltip-content tooltip-right">Erstellen Sie in wenigen Schritten eine E-Mail-Signatur, die Ihre Informationspflichten gemäß DSGVO erfüllt.<i></i></div>
        </div></span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Generator für
                    Geheimhaltungsvereinbarungen
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Nutzen Sie unseren Generator, um schnell, einfach und
                            rechtssicher eine Vertraulichkeitsvereinbarung (NDA) zu erstellen.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Home Office Datenschutz <span
                    className="fix">Generator
        <div className="tooltip"><img decoding="async"
                                      src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
          <div className="tooltip-content tooltip-right">Nutzen Sie den Home Office Generator, mit dem Sie die datenschutzrechtlich relevanten Punkte für die Arbeit im Homeoffice umsetzen können.<i></i></div>
        </div></span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Barrierefreiheitserklärung <span
                    className="fix">Generator
        <div className="tooltip"><img decoding="async"
                                      src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
          <div className="tooltip-content tooltip-right">Wollen oder müssen Sie Ihre Webseite barrierefrei gestalten, dann nutzen Sie dafür unseren extra konzipierten Generator.<i></i></div>
        </div></span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Siegel zum Einbinden auf
                    Ihrer <span className="fix">Webseite
        <div className="tooltip"><img decoding="async"
                                      src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
          <div className="tooltip-content tooltip-right">Nutzen Sie unsere exklusiven Partner-Siegel auf Ihren Webseiten.<i></i></div>
        </div></span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
        </FeatureSection>
        <FeatureSection title="Kundenservice, Chat und Erstberatung">
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Kundensupport von Mo - Fr
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Chatbetreuung von Mo - Fr
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Kostenlose anwaltliche <span
                    className="fix">Erstberatung
        <div className="tooltip"><img decoding="async"
                                      src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
          <div className="tooltip-content tooltip-right">Wenden Sie sich bei rechtlichen Fragen an die kostenfreie Erstberatung unserer Partner Kanzlei Siebert Lexow Lang.<i></i></div>
        </div></span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">VIP-Kundensupport
                    (priorisiert) <span className="fix">von Mo - Fr</span></div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">VIP-Chatbetreuung
                    (priorisiert) <span className="fix">von Mo - Fr</span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">VIP-anwaltliche (priorisiert)
                    Erstberatung
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
        </FeatureSection>
        <FeatureSection title="Know How Material, Live-Webinare, Aufzeichnungen, Tutorials, Beiträge">
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Praktische Premium Guides
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Bekommen Sie schnell und einfach einen umfassenden Überblick
                            über alle relevanten Rechtsthemen für rechtssichere Webseiten.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Monatliche Live-Webinare
                    mit <span className="fix">Experten
        <div className="tooltip"><img decoding="async"
                                      src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
          <div className="tooltip-content tooltip-right">Nehmen Sie an exklusiven und praxisorientierten Live-Webinaren zu den wichtigsten Themen rund ums Internetrecht mit erfahrenen Rechtsanwälten, Datenschutzprofis und Marketingexperten teil.<i></i></div>
        </div></span>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Webinar-Aufzeichnungen
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Greifen Sie auf unser Webinar-Archiv zurück, um sich alle
                            Aufzeichnungen der Webinare jederzeit wieder anzusehen.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Tutorials
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Sehen Sie sich unsere praktischen
                            Schritt-für-Schritt-Anleitungen über unsere zahlreichen Generatoren, Tools und Plugins
                            an.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">FAQ Sammlung
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Blättern Sie in unserer umfassenden FAQ-Sammlung nach Ihrer
                            Frage und erhalten Sie sofort die passende Antwort.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Muster und Verträge
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Nutzen Sie praktische und rechtssichere Muster, Verträge und
                            Vorlagen. Direkt als Download verfügbar.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    10
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    alle
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Checklisten
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Laden Sie sich übersichtliche Checklisten herunter, um Ihre
                            Webseiten je nach Bedarf Schritt-für-Schritt abzusichern.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Monatliche Premium Update Mails
                    <div className="tooltip"><img decoding="async"
                                                  src="https://www.e-recht24.de/mitglieder/wp-content/uploads/info-icon-grey.svg"/>
                        <div className="tooltip-content tooltip-right">Erhalten Sie einmal im Monat eine übersichtliche E-Mail mit den
                            neuesten Updates und Infos in von eRecht24 Premium.<i></i></div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <img src={check_icon} className="w-9 h-9 inline-block" alt="enthalten"/>
                </div>
            </div>
        </FeatureSection>
        <FeatureSection title="Mitgliedschaftsdauer und Zahlung">
            <div className="feature-section--row discount-row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Rabatt &amp; Ersparnis
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <div className="price monthly hide">-</div>
                    <div className="price yearly">{props.basicDiscount.percentage}% Rabatt: Sie
                        sparen {props.basicDiscount.value}
                        <div className="small-info-text">(bei Jahreszahlung)</div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <div className="price monthly hide">-</div>
                    <div className="price yearly">{props.businessDiscount.percentage}% Rabatt: Sie
                        sparen {props.businessDiscount.value}
                        <div className="small-info-text">(bei Jahreszahlung)</div>
                    </div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <div className="price monthly hide">-</div>
                    <div className="price yearly">{props.enterpriseDiscount.percentage}% Rabatt: Sie
                        sparen {props.enterpriseDiscount.value}
                        <div className="small-info-text">(bei Jahreszahlung)</div>
                    </div>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Laufzeit
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <div className="price monthly hide">1 Monat</div>
                    <div className="price yearly">12 Monate</div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <div className="price monthly hide">1 Monat</div>
                    <div className="price yearly">12 Monate</div>
                </div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">
                    <div className="price monthly hide">1 Monat</div>
                    <div className="price yearly">12 Monate</div>
                </div>
            </div>
            <div className="feature-section--row">
                <div className="text-center lg:text-left lg:pl-8 py-8 w-full lg:w-1/4">Komfortable Zahlungsarten</div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">SEPA-Lastschrift, PayPal, Kreditkarte</div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">SEPA-Lastschrift, PayPal, Kreditkarte</div>
                <div className="text-center py-8 w-1/3 lg:w-1/4">SEPA-Lastschrift, PayPal, Kreditkarte</div>
            </div>
        </FeatureSection>
    </PricingTable>
}
