const defaultCustomerData = {
    "EmailAddress" : "",
    "FirstName" : "",
    "LastName" : "",
    "Tag" : "",
    "CompanyName" : "",
    "VatId" : "",
    "PhoneNumber" : "",
    "Address" : {
        "AddressLine1": "",
        "Street": "",
        "HouseNumber": "",
        "PostalCode": "",
        "City": "",
        "Country": "DE"
    },
    "Locale" : ""
};

export default function () {
    let storedCustomerData = sessionStorage?.getItem('checkout_customer_data');

    if (!storedCustomerData) {
        return defaultCustomerData;
    }

    storedCustomerData = JSON.parse(storedCustomerData);
    let isValid = true;

    Object.keys(defaultCustomerData).forEach(function (key) {
        if (!storedCustomerData.hasOwnProperty(key)) {
            isValid = false;
        }
    });

    if (!isValid) {
        return defaultCustomerData;
    }

    Object.keys(defaultCustomerData.Address).forEach(function (key) {
        if (!storedCustomerData.Address.hasOwnProperty(key)) {
            isValid = false;
        }
    });

    if (!isValid) {
        return defaultCustomerData;
    }

    return storedCustomerData;
}
