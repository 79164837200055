import Card from "@erecht24/frontend-kit/theme/components/cards/Card";
import CardBody from "@erecht24/frontend-kit/theme/components/cards/CardBody";
import AddressForm from "./AddressForm";
import {useContext} from "react";
import {StateContext} from "../Contexts";
import AddressSummary from "./AddressSummary";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    return <Card {...props}>
        <CardBody>
            {(state.addressFormEnabled || !state.contractData?.customer?.Address?.City) &&
                <AddressForm></AddressForm>
            }
            {(!state.addressFormEnabled && state.contractData?.customer?.Address?.City) &&
                <AddressSummary></AddressSummary>
            }
        </CardBody>
    </Card>
}
