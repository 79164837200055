import Dialog from "@erecht24/frontend-kit/theme/components/dialogs/Dialog";
import DialogBody from "@erecht24/frontend-kit/theme/components/dialogs/DialogBody";
import {useContext} from "react";
import {StateContext} from "../Contexts";
import Line from "@erecht24/frontend-kit/theme/components/misc/Line";
import DialogHeader from "@erecht24/frontend-kit/theme/components/dialogs/DialogHeader";
import {SVGCross} from "@erecht24/frontend-kit/theme/components/misc/SVG";
import {getCurrentPlan, getMonthlyPriceForPlanVariant, getPlanVariantForPeriod} from "../Billwerk/SubscriptionJS";
import {PERIOD_ANNUALLY, PERIOD_MONTHLY} from "../Constants";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    const handleOpen = () => state.setPlanDetailsOpen(!state.planDetailsOpen);

    let plan = getCurrentPlan(state);
    let planVariantMonthly = getPlanVariantForPeriod(state, PERIOD_MONTHLY);
    let planVariantAnnually = getPlanVariantForPeriod(state, PERIOD_ANNUALLY);

    return <Dialog open={state.planDetailsOpen} handler={handleOpen} size="sm">
        <DialogHeader>
            <div className="flex gap-2 justify-end">
                <div className="flex-initial cursor-pointer pt-6 pr-6"
                     onClick={handleOpen}>
                    <SVGCross/>
                </div>
            </div>
            <h3 className="h3 !text-3xl mb-10 px-8 md:px-20 pt-4 md:pt-14">{state.cartViewData?.plan.title}</h3>
        </DialogHeader>
        <DialogBody className="px-8 md:px-20 pb-8 md:pb-20">
            <div className="flex flex-row self-stretch justify-between mb-10">
                <div className="font-bold">
                    <span className="block text-xl text-blue-dark leading-[1.375rem]">Monatlich</span>
                    <span className={(planVariantMonthly === null ? 'skeleton ' : '') + `text-[2.5rem] text-blue leading-[normal]`}>
                        {planVariantMonthly === null ? '??' : getMonthlyPriceForPlanVariant(planVariantMonthly)}&euro;
                    </span>
                    <span className="text-blue text-xl">/mtl</span>
                </div>
                <Line className="!h-[80px] w-px border-l"></Line>
                <div className="font-bold">
                    <span className="block text-xl text-blue-dark leading-[1.375rem]">Jährlich</span>
                    <span className={(planVariantAnnually === null ? 'skeleton ' : '') + `text-[2.5rem] text-blue leading-[normal]`}>
                        {planVariantAnnually === null ? '??' : getMonthlyPriceForPlanVariant(planVariantAnnually)}&euro;
                    </span>
                    <span className="text-blue text-xl">/mtl</span>
                </div>
            </div>
            {plan?.custom_fields?.ProductBenefits &&
                <div className="plan-details--list" dangerouslySetInnerHTML={{__html: plan?.custom_fields?.ProductBenefits}}>
                </div>
            }
        </DialogBody>
    </Dialog>
}
