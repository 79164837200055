import ButtonTransparent from "@erecht24/frontend-kit/theme/components/buttons/ButtonTransparent";
import Input from "@erecht24/frontend-kit/theme/components/inputs/Input";
import {useContext, useState} from "react";
import {StateContext} from "../Contexts";
import CouponCode from "./CouponCode";
import ValidationToolTip from "./ValidationToolTip";
import {addToCart} from "../Billwerk/SubscriptionJS";
import {track} from "../Util/tracking";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);
    const [coupon, setCoupon] = useState(state.coupon ?? '');
    const [couponError, setCouponError] = useState(null);

    const onEnter = (event, callback) => {
        if (event.key === 'Enter') {
            callback();
        }
    }

    const updateCoupon = () => {
        state.setCoupon(coupon);
        state.setCart(cart => addToCart(state, cart, {
            couponCode: coupon
        }));
        setCoupon('');
        setCouponError(null);
    };

    if (couponError === null && state.cartViewData?.couponCode && !state.cartViewData.couponAppliesToCart) {
        setCouponError('Der eingegebene Gutschein ' + state.cartViewData?.couponCode + ' ist leider nicht gültig.');
        state.setCoupon('');
        state.setCart(cart => addToCart(state, cart, {
            couponCode: coupon
        }));
        setCoupon('');
        track(null, 'checkout', 'voucher_submitted', 'redeemed_voucher', 'false');
    }

    return <div {...props} className={`flex flex-col ${props.className ?? ''}`}>
        <div className="flex flex-row justify-between relative gap-4">
            <div className="relative w-full">
                <Input label="Gutscheincode"
                       variant="outlined"
                       size="md_uc"
                       defaultValue={coupon}
                       value={coupon}
                       onChange={(event) => {
                           setCoupon(event.target.value)

                           if (!event.target.value) {
                               updateCoupon();
                           }
                       }}
                       onKeyDown={(event) => onEnter(event, () => track(updateCoupon, 'checkout', 'voucher_submitted', 'redeemed_voucher', 'true'))}
                       error={couponError !== null}
                ></Input>
                {(couponError !== null) && (
                    <ValidationToolTip>{couponError}</ValidationToolTip>
                )}
            </div>
            <ButtonTransparent onClick={() => track(updateCoupon, 'checkout', 'voucher_submitted', 'redeemed_voucher', 'true')} disabled={state.coupon && state.cartViewData?.couponAppliesToCart}>einlösen</ButtonTransparent>
        </div>
        <div className="mt-4">
            {(state.cartViewData?.couponCode && state.cartViewData?.couponAppliesToCart) && (
                <CouponCode>{state.cartViewData?.couponCode}</CouponCode>
            )}
        </div>
    </div>
}
