import Breadcrumbs from "@erecht24/frontend-kit/theme/components/breadcrumbs/Breadcrumbs";
import {STEP_ADDRESS, STEP_PAYMENT, STEP_PLAN} from "../Constants";
import {useContext} from "react";
import {StateContext} from "../Contexts";
import {track} from "../Util/tracking";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    const goto = (target) => {
        if (state.step === target) return;
        state.setStep(target);
    };

    return <div className="hidden xl:block">
        <Breadcrumbs>
            <button
                className={state.step === STEP_PLAN ? `text-blue-dark cursor-default` : ``}
                onClick={() => track(
                    () => goto(STEP_PLAN),
                    'checkout',
                    'navigation',
                    'checkout_menu',
                    'tariff'
                )}
            >Tarif konfigurieren</button>
            <button
                className={state.step === STEP_ADDRESS ? `text-blue-dark cursor-default` : ``}
                onClick={() => track(
                    () => goto(STEP_ADDRESS),
                    'checkout',
                    'navigation',
                    'checkout_menu',
                    'address'
                )}
            >Bestellung abschließen</button>
        </Breadcrumbs>
    </div>
}
