import {useContext} from "react";
import {StateContext} from "../Contexts";
import plusIcon from "../../../img/plus.svg";
import dashIcon from "../../../img/dash.svg";
import {addToCart, getCurrentPlan} from "../Billwerk/SubscriptionJS";
import Alert from "@erecht24/frontend-kit/theme/components/alerts/Alert";
import Tooltip from "./Tooltip";
import {plural} from "../Util/plural";
import {trackComponent} from "../Util/tracking";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    /** @type App_Http_Resources_Component */
    let component = props.component;

    const saveState = (newQuantity) => {
        trackComponent(() => {
            state.setCart(cart => addToCart(state, cart, {
                component: component,
                componentQuantity: newQuantity
            }));
        }, 'addEcommerceItem', component, newQuantity);
    }

    const getCurrentQuantity = () => {
        return state.cart.components[component.external_id]?.quantity ?? 0;
    }

    const updateState = (diff) => {
        let newQuantity = getCurrentQuantity() + diff;
        saveState(newQuantity);
    };

    const decr = () => updateState(-1);
    const incr = () => updateState(1);

    let components_added_by_default = 0;

    let plan = getCurrentPlan(state);

    let additionalComponentsHint = <p className="text-graphite leading-[normal] mt-1 mb-6">
        Ihr ausgewählter Tarif beinhaltet bereits <span className="font-bold">{component.plan_components?.quantity} {component.name}</span>.
        Wenn Sie mehr als {component.plan_components?.quantity} {component.name} benötigen, können Sie diese hier kostenpflichtig hinzufügen.
    </p>;

    if (component.external_id === 'extraprojekt') {
        if (state.cartViewData?.cartType === 'downgrade_purchase') {
            if (state.activeProjectsCount > plan?.components?.extraprojekt?.plan_components?.quantity) {
                components_added_by_default = state.activeProjectsCount - plan?.components?.extraprojekt?.plan_components?.quantity;

                additionalComponentsHint = <p className="text-graphite leading-[normal] mt-1 mb-6">
                    Ihr ausgewählter Tarif beinhaltet <span
                    className="font-bold">{plan?.components?.extraprojekt?.plan_components?.quantity} {plural('Projekt', plan?.components?.extraprojekt?.plan_components?.quantity, 'e')}</span>.
                    Sie haben
                    aktuell {state.activeProjectsCount} {state.activeProjectsCount === 1 ? `aktives Projekt` : `aktive Projekte`}.
                    Wir haben
                    daher {components_added_by_default} {plural('Projekt', components_added_by_default, 'e')} hinzugefügt.
                    Weitere Projekte können Sie hier kostenpflichtig hinzufügen.
                </p>;
            } else {
                additionalComponentsHint = <p className="text-graphite leading-[normal] mt-1 mb-6">
                    Ihr ausgewählter Tarif beinhaltet <span
                    className="font-bold">{plan?.components?.extraprojekt?.plan_components?.quantity} {plural('Projekt', plan?.components?.extraprojekt?.plan_components?.quantity, 'e')}</span>.
                    Sie haben
                    aktuell {state.activeProjectsCount} {state.activeProjectsCount === 1 ? `aktives Projekt` : `aktive Projekte`}.
                    Weitere Projekte können Sie hier kostenpflichtig hinzufügen.
                </p>;
            }
        } else {
            additionalComponentsHint = <p className="text-graphite leading-[normal] mt-1 mb-6">
                Ihr ausgewählter Tarif beinhaltet bereits <span className="font-bold">{plan?.components?.inklusivprojekt?.plan_components?.quantity} {component.name}</span>.
                Wenn Sie mehr als {plan?.components?.inklusivprojekt?.plan_components?.quantity} {component.name} benötigen, können Sie diese hier kostenpflichtig hinzufügen.
            </p>;
        }
    }

    return <div>
        {(state.cartViewData?.cartType === 'downgrade_purchase' && components_added_by_default > 0 && getCurrentQuantity() === components_added_by_default) &&
            <Alert className="mb-6">
                <strong>Ihr aktueller Tarif enthält weniger Komponenten, als Ihr bisheriger
                    Tarif.</strong> Wir haben die Komponenten nachfolgend so vorausgewählt, dass Sie
                keine Nachteile haben.
            </Alert>
        }
        <div className="flex flex-row items-center">
            <h4 className="h3">Weitere {component.name} hinzufügen</h4>
            {component.product_infotext &&
                <Tooltip>{component.product_infotext}</Tooltip>
            }
        </div>
        {additionalComponentsHint}
        <div className="flex flex-row gap-6 items-center">
            <div className="project-count-configurator">
                <button onClick={decr} disabled={getCurrentQuantity() <= 0}>
                    <img src={dashIcon} alt={component.name + ` reduzieren`}/>
                </button>
                <input type="number"
                       value={getCurrentQuantity()}
                       min="0"
                       max={component.plan_components?.max > 0 ? component.plan_components?.max : null}
                       className="text-2xl"
                       onChange={(event) => {
                           let quantity = parseInt(event.target.value);

                           if (Number.isNaN(quantity)) {
                               quantity = 0;
                           } else if (component.plan_components?.max > 0 && quantity > component.plan_components?.max) {
                               quantity = component.plan_components?.max;
                           }

                           saveState(quantity);
                       }}/>
                <button onClick={incr} disabled={getCurrentQuantity() >= component.plan_components?.max}>
                    <img src={plusIcon} alt={component.name + ` erhöhen`}/>
                </button>
            </div>
            <span>Weitere {component.name}</span>
        </div>
    </div>
}
