import {useContext} from "react";
import {StateContext} from "../Contexts";
import {addToCart} from "../Billwerk/SubscriptionJS";
import {track} from "../Util/tracking";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    const clearCoupon = () => {
        state.setCoupon(null);
        state.setCart(cart => addToCart(state, cart, {
            couponCode: null
        }));
    };

    return <div {...props} className={`inline bg-gray-light text-white font-bold px-4 py-1.5 rounded-[32px] ${props.className ?? ''}`}>
        <span>{props.children}</span>
        <button onClick={() => track(clearCoupon, 'checkout', 'voucher_removed')} className="ml-[1ch]">X</button>
    </div>
}
