import Breadcrumbs from "@erecht24/frontend-kit/theme/components/breadcrumbs/Breadcrumbs";
import {STEP_PAYMENT, STEP_PLAN} from "../Constants";
import {useContext} from "react";
import {StateContext} from "../Contexts";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    const goto = (target) => {
        if (state.step === target) return;
        state.setStep(target);
    };

    return <div>
        <div className="hidden xl:block">
            <Breadcrumbs>
                <button
                    className={state.step === STEP_PLAN ? `text-blue-dark cursor-default` : ``}
                    onClick={() => goto(STEP_PLAN)}
                >Tarif wechseln</button>
                <button
                    className={state.step === STEP_PAYMENT ? `text-blue-dark cursor-default` : ``}
                    onClick={() => goto(STEP_PAYMENT)}
                >Bestellung abschließen</button>
            </Breadcrumbs>
        </div>
        <div className="flex flex-row justify-center gap-[10px] xl:hidden">
            <button
                className={(state.step === STEP_PLAN ? `bg-blue` : `bg-gray-dark opacity-25`) + ` block w-2.5 h-2.5 rounded-[10px]`}
                onClick={() => goto(STEP_PLAN)}
            >
                <span className="sr-only">Tarif wechseln</span>
            </button>
            <button
                className={(state.step === STEP_PAYMENT ? `bg-blue` : `bg-gray-dark opacity-25`) + ` block w-2.5 h-2.5 rounded-[10px]`}
                onClick={() => goto(STEP_PAYMENT)}
            >
                <span className="sr-only">Bestellung abschließen</span>
            </button>
        </div>
        <div className="text-3xl text-blue-dark text-center font-bold mt-5 mb-10 xl:hidden">
            {state.step === STEP_PLAN &&
                <>Tarif wechseln</>
            }
            {state.step === STEP_PAYMENT &&
                <>Bestellung abschließen</>
            }
        </div>
    </div>
}
