import IncrementalNumberControls from "./IncrementalNumberControls";
import IntervalSelectionControl from "./IntervalSelectionControl";

export default function (props) {
    /** @type App_Http_Resources_Component */
    let component = props.component;

    if (component.plan_components.step_mode === 'incremental') {
        return <IncrementalNumberControls component={component}></IncrementalNumberControls>
    }

    if (component.plan_components.step_mode === 'steps') {
        return <IntervalSelectionControl component={component}></IntervalSelectionControl>
    }

    return <></>
}
