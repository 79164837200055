import {useContext, useState} from "react";
import {StateContext} from "../Contexts";
import Alert from "@erecht24/frontend-kit/theme/components/alerts/Alert";
import {getLabelForCountryCode} from "../Util/countries";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    return <>
        <Alert className="mb-6">
            <span className="font-bold">Bitte prüfen Sie Ihre Anschrift</span> und passen Sie diese bei Bedarf an.
        </Alert>
        <div className="flex flex-row mb-6 justify-between align-items-center">
            <h3 className="h3">Anschrift</h3>
            <button className="font-bold text-blue underline text-lg" onClick={() => state.setAddressFormEnabled(true)}>
                Bearbeiten
            </button>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 text-graphite">
            <div className="grid gap-4">
                {state.customerData?.CompanyName &&
                    <p>Firma<br/><strong>{state.customerData.CompanyName}</strong></p>
                }
                <p>Name<br/><strong>{state.customerData.FirstName} {state.customerData.LastName}</strong></p>
                <p>E-Mail-Adresse<br/><strong>{state.customerData.EmailAddress}</strong></p>
                {state.customerData?.VatId &&
                    <p>Umsatzsteuer-ID<br/><strong>{state.customerData.VatId}</strong></p>
                }
            </div>
            <div>
                <p>
                    Anschrift<br/>
                    <strong>
                        {state.customerData.Address.Street} {state.customerData.Address.HouseNumber}<br/>
                        {state.customerData.Address.PostalCode} {state.customerData.Address.City}<br/>
                        {getLabelForCountryCode(state.countries, state.customerData.Address.Country)}
                    </strong>
                </p>
            </div>
        </div>
    </>
}
