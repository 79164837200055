import {STEP_ADDRESS, STEP_PAYMENT, STEP_PLAN, STEP_VALUES} from "../Constants";
import validateCustomerData from "./validate";
import CustomerData from "../Billwerk/CustomerData";
import StoredState from "./StoredState";

export default function () {
    let targetStep = sessionStorage?.getItem('checkout_current_step');

    if (null === targetStep) {
        if ([STEP_PLAN, STEP_ADDRESS, STEP_PAYMENT].includes(window.location.hash?.substring(1))) {
            targetStep = window.location.hash?.substring(1);
        } else {
            targetStep = STEP_PLAN;
        }
    }

    // When trying to navigate to or beyond the address field without a selected plan, bump the user back to the plan
    // step
    if (STEP_VALUES[targetStep] > 0) {
        let cart = StoredState('checkout_cart', null)

        if (!cart?.PlanVariantId) {
            targetStep = STEP_PLAN;
        }
    }

    // if some tries to go directly to payment step with invalid address, we go back to address
    if (STEP_VALUES[targetStep] > 1) {
        let customerData = CustomerData();
        let errors = validateCustomerData(customerData);

        if (errors.length > 0) {
            targetStep = STEP_ADDRESS;
        }
    }

    return targetStep;
}
