import Card from "@erecht24/frontend-kit/theme/components/cards/Card";
import CardBody from "@erecht24/frontend-kit/theme/components/cards/CardBody";
import {StateContext} from "../Contexts";
import {useContext} from "react";
import {
    getCurrentPlan,
    getCurrentPlanVariant,
    getMonthlyPriceForPlanVariant,
    getPlanVariantForPeriod
} from "../Billwerk/SubscriptionJS";
import formatCurrency from "../Util/formatCurrency";
import jahrestarif_siegel from "../../../img/jahrestarif-siegel.svg";
import {PERIOD_ANNUALLY, PERIOD_ANNUALLY_MONTHLY_PAID} from "../Constants";
import PaymentVariantButton from "./PaymentVariantButton";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    let plan = getCurrentPlan(state);
    let planVariant = getCurrentPlanVariant(state);

    if (planVariant?.recurring_fee < 0.01)
        return <></>

    return <div className="flex flex-col gap-10">
        <Card>
            <CardBody>
                <div className="flex flex-col lg:flex-row items-center lg:items-start self-stretch justify-center lg:justify-between mb-10 gap-6">
                    <div className="flex flex-1 flex-col items-center lg:items-start justify-start gap-2">
                        {state.cartViewData && (
                            <>
                                {planVariant?.name_override && <h3 className="h3 text-center lg:text-left text-balance lg:text-wrap">{planVariant?.name_override}</h3>}
                                {(!planVariant?.name_override && plan?.name) && <h3 className="h3 text-center lg:text-left text-balance lg:text-wrap">{plan.name}</h3>}
                                {(!planVariant?.name_override && !plan?.name) &&
                                    <h3 className="h3 text-center lg:text-left text-balance lg:text-wrap skeleton w-full">&nbsp;</h3>}
                            </>
                        )}
                        {!state.cartViewData && (
                            <>
                                <h3 className="h3 h-[30px] w-[150px] skeleton"></h3>
                            </>
                        )}
                    </div>
                    <div className="text-blue text-lg">
                        Jahrestarif
                    </div>
                </div>
                <div className={`flex flex-col lg:flex-row justify-between items-center active p-4 tile relative focus:ring`}>
                    <div className="flex flex-col lg:flex-row items-center leading-[2.5rem]">
                        <span
                            className={(planVariant === null ? 'skeleton' : '') + ` text-[2.5rem] font-bold`}>{planVariant === null ? <>?? &euro;</> : formatCurrency(getMonthlyPriceForPlanVariant(planVariant), true)}</span>
                        <span className="text-gray-dark text-lg ml-2">
                            {planVariant?.strikethrough_price &&
                                <span className="strikethrough-price">
                                    {formatCurrency(planVariant?.strikethrough_price / 12., true)}
                                </span>
                            }
                            / monatlich
                            {planVariant?.fee_period_unit === 'Year' &&
                                <> mit jährlicher Zahlung</>
                            }
                        </span>
                    </div>
                    {planVariant?.sale_badge &&
                        <div className="discount-percent-label leading-[1.1875rem]">{planVariant.sale_badge}</div>
                    }
                </div>
                {getPlanVariantForPeriod(state, PERIOD_ANNUALLY_MONTHLY_PAID) &&
                    <div className="mt-6">
                        <PaymentVariantButton></PaymentVariantButton>
                    </div>
                }
            </CardBody>
        </Card>
        {plan?.custom_fields?.ProductBenefits &&
            <Card className="tile active">
                <CardBody className="pr-[1.5rem] md:pr-[150px]">
                    <div className="plan-details--list annual-plan" dangerouslySetInnerHTML={{__html: plan?.custom_fields?.ProductBenefits}}>
                    </div>
                    <img className="absolute hidden md:inline top-0 right-10" src={jahrestarif_siegel} alt="25 Jahre Expertise"/>
                </CardBody>
            </Card>
        }
    </div>
}
