import formatCurrency from "../Util/formatCurrency";

export default function (props) {
    let hasComponents = props.components?.length > 0;

    if (!hasComponents) {
        return <></>
    }

    return <div className="grid grid-cols-1 gap-6">
        {props.components.map((entry) => {
            let showQuantity = entry.type
                ? (entry.type === 'QuantityBased')
                : ((!entry.includedInPlan && entry.quantity > 0) || (entry.includedInPlan && entry.quantity > 1));
            let cols = showQuantity ? 'grid-cols-3' : 'grid-cols-2';

            return <div className={'grid ' + cols + ' text-base ' + (entry.price < 0 ? 'lg:text-lg' : 'lg:text-xl')}>
                <div className={`text-blue-dark font-bold`}>{entry.title}</div>
                {showQuantity &&
                    <div className="text-graphite text-center">{entry.quantity.toLocaleString()}</div>
                }
                {entry.includedInPlan && !entry.title.includes('inklusive') &&
                    <div className={`text-right font-bold text-blue-dark`}>inklusive</div>
                }
                {!entry.includedInPlan &&
                    <div className={`text-right font-bold ` + (entry.price < 0 ? 'text-green' : 'text-blue-dark')}>{formatCurrency(entry.price)}</div>
                }
            </div>
        })}
   </div>

}
