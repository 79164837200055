import Input from "@erecht24/frontend-kit/theme/components/inputs/Input";
import {useContext} from "react";
import {StateContext} from "../Contexts";
import {validateCreditCardPaymentData} from "../Util/validate";
import ValidationToolTip from "./ValidationToolTip";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    const updatePaymentData = (field, value) => {
        state.paymentData[field] = value;
        state.setPaymentData(state.paymentData);
    };

    const updatePaymentDataValidation = async () => {
        let validation = validateCreditCardPaymentData(state.paymentData);
        state.setPaymentDataValidation(validation);
    };

    const makeProps = (target) => {
        let value = state.paymentData[target];

        return {
            defaultValue: value,
            onChange: (event) => {
                updatePaymentData(target, event.target.value);
            },
            onBlur: (event) => {
                updatePaymentDataValidation()
            },
            error: state.paymentDataValidation.includes(target)
        };
    };

    return <>
        <h3 className="h3 mb-[3rem]">Kreditkartendaten</h3>
        <div className="flex flex-col gap-6">
            <div className="relative">
                <Input label="Karteninhaber *" placeholder="Max Mustermann" {...makeProps('cardHolder')}/>
                {state.paymentDataValidation.includes('cardHolder') &&
                    <ValidationToolTip>Bitte geben Sie den Karteninhaber an.</ValidationToolTip>
                }
            </div>
            <div className="relative">
                <Input label="Kartennummer *" {...makeProps('cardNumber')}/>
                {state.paymentDataValidation.includes('cardNumber') &&
                    <ValidationToolTip>Bitte geben Sie die Kartennummer an.</ValidationToolTip>
                }
            </div>
            <div className="grid grid-cols-3 gap-6">
                <div className="relative">
                    <Input label="Monat *" placeholder="MM" minlength="2" maxlength="2" {...makeProps('expiryMonth')}/>
                    {state.paymentDataValidation.includes('expiryMonth') &&
                        <ValidationToolTip>Bitte geben Sie einen Monat von 01 bis 12 an.</ValidationToolTip>
                    }
                </div>
                <div className="relative">
                    <Input label="Jahr *" placeholder="JJ" minlength="2" maxlength="2" {...makeProps('expiryYear')}/>
                    {state.paymentDataValidation.includes('expiryYear') &&
                        <ValidationToolTip>Bitte geben Sie ein gültiges Jahr an.</ValidationToolTip>
                    }
                </div>
                <div className="relative">
                    <Input label="Prüfnummer *" placeholder="123" {...makeProps('cvc')}/>
                    {state.paymentDataValidation.includes('cvc') &&
                        <ValidationToolTip>Bitte geben Sie die Prüfnummer Ihrer Kreditkarte an.</ValidationToolTip>
                    }
                </div>
            </div>
        </div>
    </>
}
