import Plan from "./Plan";
import {useContext, useEffect, useRef} from "react";
import {StateContext} from "../Contexts";
import {STEP_ADDRESS, STEP_PAYMENT, STEP_PLAN} from "../Constants";
import Address from "./Address";
import Payment from "./Payment";
import Breadcrumbs from "./Breadcrumbs";
import PlanDetails from "./PlanDetails";
import PlanDialog from "../../PricingTable/PlanDialog";
import {getCurrentPlanVariant, getPlanVariantByExternalId, getPlanVariantPeriod} from "../Billwerk/SubscriptionJS";
import Alert from "@erecht24/frontend-kit/theme/components/alerts/Alert";
import ButtonPrimary from "@erecht24/frontend-kit/theme/components/buttons/ButtonPrimary";
import validate from "../Util/validate";
import UpgradeBreadcrumbs from "./UpgradeBreadcrumbs";
import {getShortDate} from "../Util/formatDate";
import PlanQuantityBasedComponents from "./PlanQuantityBasedComponents";
import PlanOnOffComponents from "./PlanOnOffComponents";
import AnnualPlan from "./AnnualPlan";
import useAnnualPlanMode from "../Util/useAnnualPlanMode";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    /** @type {MutableRefObject<HTMLDivElement>} */
    const addressRef = useRef();

    /** @type {MutableRefObject<HTMLDivElement>} */
    const paymentRef = useRef();

    let currentPlanVariant = getCurrentPlanVariant(state);
    let currentCategory = currentPlanVariant?.external_id?.substring(0, currentPlanVariant?.external_id?.indexOf('-'));

    if (!['agency', 'website', 'shop'].includes(currentCategory)) {
        currentCategory = 'agency';
    }

    let showPlanOptions = !state.mainProps.hideQuantityComponents;
    let showPlanAddons = !state.mainProps.hideOnOffComponents;

    let annualPlanMode = useAnnualPlanMode(state);

    const goToSummary = async () => {
        let validation = await validate(state.customerData);
        state.setCustomerDataValidation(validation);

        if (validation.length < 1) {
            state.setStep(STEP_PAYMENT);
        } else {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        if (state.step !== STEP_ADDRESS || state.goto === null) {
            return;
        }

        if (state.goto === STEP_ADDRESS) {
            addressRef.current.scrollIntoView();
        }

        if (state.goto === STEP_PAYMENT) {
            paymentRef.current.scrollIntoView();
        }

        state.setGoto(null);
    });

    return <div>
        {state.cartViewData?.cartType === 'initial_purchase' &&
            <Breadcrumbs></Breadcrumbs>
        }
        {(state.cartViewData?.cartType === 'upgrade_purchase' || state.cartViewData?.cartType === 'downgrade_purchase') &&
            <UpgradeBreadcrumbs></UpgradeBreadcrumbs>
        }
        {state.step === STEP_PLAN &&
            <>
                {state.cartViewData?.cartType === 'upgrade_purchase' &&
                    <Alert className="mb-10">
                        <strong>Sie wechseln Ihren Tarif.</strong> Ihr
                        aktueller Tarif ist <span className="text-blue">{state.contractData?.plan?.name}</span>.
                        Ihr neuer Tarif ist <strong>sofort für Sie verfügbar</strong>.
                    </Alert>
                }
                {state.cartViewData?.cartType === 'downgrade_purchase' &&
                    <Alert className="mb-10">
                        <strong>Sie wechseln Ihren Tarif.</strong> Ihr
                        aktueller Tarif ist <span className="text-blue">{state.contractData?.plan?.name}</span>.
                        Ihr neuer Tarif ist <strong>erst nach Ablauf der Vertragslaufzeit am {getShortDate(state.cartViewData?.changeDate)} für Sie verfügbar</strong>.
                    </Alert>
                }
                <div className="flex flex-col gap-10">
                    {state.productInfo &&
                        <>
                            {annualPlanMode &&
                                <AnnualPlan></AnnualPlan>
                            }
                            {!annualPlanMode &&
                                <Plan></Plan>
                            }
                        </>
                    }
                    {(showPlanOptions || showPlanAddons) &&
                        <>
                            {showPlanOptions && <PlanQuantityBasedComponents></PlanQuantityBasedComponents>}
                            {state.cartViewData && showPlanAddons && <PlanOnOffComponents withLine={currentPlanVariant?.recurring_fee > 0}></PlanOnOffComponents>}
                        </>
                    }

                    {state.productInfo &&
                        <>
                            <PlanDetails/>
                            { !annualPlanMode && <PlanDialog selectedCategory={currentCategory} forceSelection={!state.cart?.PlanVariantId}/>}
                        </>
                    }
                </div>
            </>
        }
        {(state.step === STEP_ADDRESS || state.step === STEP_PAYMENT) &&
            <>
                <div ref={addressRef}>
                    <Address className="mb-[2.5rem]"></Address>
                </div>
                <div ref={paymentRef}>
                    <Payment></Payment>
                </div>
                {(state.step === STEP_ADDRESS) &&
                    <ButtonPrimary onClick={goToSummary}
                                   fullWidth={true}
                                   className="checkout--form--next"
                                   disabled={state.customerDataValidation.length + state.paymentDataValidation.length > 0}
                    >
                        Zusammenfassung anzeigen
                    </ButtonPrimary>
                }
            </>
        }
    </div>
}
