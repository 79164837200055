import {STEP_ADDRESS, STEP_PAYMENT, STEP_PLAN} from "../Constants";
import {useContext} from "react";
import {StateContext} from "../Contexts";
import {track} from "../Util/tracking";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    const goto = (target) => {
        if (state.step === target) return;
        state.setStep(target);
    };

    return <>
        <div className="flex flex-row justify-center gap-[10px] xl:hidden">
            <button
                className={(state.step === STEP_PLAN ? `bg-blue` : `bg-gray-dark opacity-25`) + ` block w-2.5 h-2.5 rounded-[10px]`}
                onClick={() => track(
                    () => goto(STEP_PLAN),
                    'checkout',
                    'navigation',
                    'checkout_menu',
                    'tariff'
                )}
            >
                <span className="sr-only">Tarif konfigurieren</span>
            </button>
            <button
                className={(state.step === STEP_ADDRESS ? `bg-blue` : `bg-gray-dark opacity-25`) + ` block w-2.5 h-2.5 rounded-[10px]`}
                onClick={() => track(
                    () => goto(STEP_ADDRESS),
                    'checkout',
                    'navigation',
                    'checkout_menu',
                    'address'
                )}
            >
                <span className="sr-only">Adresse eingeben</span>
            </button>
            <button
                className={(state.step === STEP_PAYMENT ? `bg-blue` : `bg-gray-dark opacity-25`) + ` block w-2.5 h-2.5 rounded-[10px]`}
                onClick={() => goto(STEP_PAYMENT)}
            >
                <span className="sr-only">Bestellübersicht</span>
            </button>
        </div>
        <div className="text-3xl text-blue-dark text-center font-bold mt-5 mb-10 xl:hidden">
            {state.step === STEP_PLAN &&
                <>Tarif konfigurieren</>
            }
            {state.step === STEP_ADDRESS &&
                <>Adresse eingeben</>
            }
            {state.step === STEP_PAYMENT &&
                <>Bestellübersicht</>
            }
        </div>
    </>
}
