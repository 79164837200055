import Input from "@erecht24/frontend-kit/theme/components/inputs/Input";
import Select from "@erecht24/frontend-kit/theme/components/selects/Select";
import Option from "@erecht24/frontend-kit/theme/components/selects/Option";
import {useContext, useState} from "react";
import {StateContext} from "../Contexts";
import validate from "../Util/validate";
import {countries as fallbackCountries, getVatIdPlaceholder} from "../Util/countries";
import ValidationToolTip from "./ValidationToolTip";
import {updateCart} from "../Billwerk/SubscriptionJS";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);
    const [focusedField, setFocusedField] = useState(null);

    const updateCustomerData = (field, value) => {
        let updatedCustomerData = JSON.parse(JSON.stringify(state.customerData));

        if (field.indexOf('.') > -1) {
            let split = field.split('.');
            field = split[0];
            let newValue = updatedCustomerData[field];
            newValue[split[1]] = value;
            value = newValue;
        }

        updatedCustomerData[field] = value;
        state.setCustomerData(updatedCustomerData);
    };

    const updateCustomerDataValidation = async (target) => {
        let validation = await validate(state.customerData);

        state.setCustomerDataValidation(validation.length < 1
            ? validation // validation has no errors
            : validation.filter((field) => field === target) // show only error on last edited field if AGB checkbox is not checked
        );

        return validation;
    };

    const makeProps = (target) => {
        let value;

        if (target.indexOf('.') > -1) {
            let split = target.split('.');
            value = state.customerData[split[0]][split[1]];
        } else {
            value = state.customerData[target];
        }

        return {
            defaultValue: value,
            onChange: (event) => {
                updateCustomerData(target, event.target.value);
            },
            onBlur: (event) => {
                updateCustomerDataValidation(target).then((validation) => {
                    if (target === 'VatId' && !validation.includes('VatId')) {
                        updateCart(state);
                    }
                });
                setFocusedField(null);
            },
            onFocus: (event) => {
                setFocusedField(target);
            },
            error: state.customerDataValidation.includes(target)
        };
    };

    let countries = state.countries ?? fallbackCountries;

    return <>
        <h3 className="h3 mb-[3rem]">Rechnungsanschrift</h3>
        <div className="flex flex-col gap-6" {...(focusedField ? {'data-focused-field': focusedField} : {})}>
            <div className="relative">
                <Input label="E-Mail-Adresse*" type="email" placeholder="max.mustermann@gmail.com" {...makeProps('EmailAddress')}/>
                {state.customerDataValidation.includes('EmailAddress') &&
                    <ValidationToolTip>Bitte geben Sie eine gültige E-Mail-Adresse an.</ValidationToolTip>
                }
            </div>
            <div className="flex flex-col md:flex-row gap-6">
                <div className="relative flex-1">
                    <Input label="Name*" placeholder="Mustermann" {...makeProps('LastName')}/>
                    {state.customerDataValidation.includes('LastName') &&
                        <ValidationToolTip>Bitte geben Sie Ihren Namen an.</ValidationToolTip>
                    }
                </div>
                <div className="relative flex-1">
                    <Input label="Vorname*" placeholder="Max" {...makeProps('FirstName')}/>
                    {state.customerDataValidation.includes('FirstName') &&
                        <ValidationToolTip>Bitte geben Sie Ihren Vornamen an.</ValidationToolTip>
                    }
                </div>
            </div>
            <div className="relative">
                <Input label="Firma*" placeholder="Firma" {...makeProps('CompanyName')}/>
                {state.customerDataValidation.includes('CompanyName') &&
                    <ValidationToolTip>Bitte geben Sie den Namen Ihres Unternehmens an.</ValidationToolTip>
                }
            </div>
            <div className="grid grid-cols-6 gap-6">
                <div className="relative col-span-4 md:col-span-5">
                    <Input label="Straße*" placeholder="Musterstraße" {...makeProps('Address.Street')}/>
                    {state.customerDataValidation.includes('Address.Street') &&
                        <ValidationToolTip>Bitte geben Sie eine Straße an.</ValidationToolTip>
                    }
                </div>
                <div className="relative col-span-2 md:col-span-1">
                    <Input label="Hausnr.*" placeholder="1" {...makeProps('Address.HouseNumber')}/>
                    {state.customerDataValidation.includes('Address.HouseNumber') &&
                        <ValidationToolTip>Bitte geben Sie eine Hausnummer an.</ValidationToolTip>
                    }
                </div>
            </div>
            <div className="grid grid-cols-6 gap-6">
                <div className="relative col-span-2 md:col-span-1">
                    <Input label="PLZ*" placeholder="12345" {...makeProps('Address.PostalCode')}/>
                    {state.customerDataValidation.includes('Address.PostalCode') &&
                        <ValidationToolTip>Bitte geben Sie eine Postleitzahl an.</ValidationToolTip>
                    }
                </div>
                <div className="relative col-span-4 md:col-span-2">
                    <Input label="Ort*" placeholder="Musterstadt" {...makeProps('Address.City')}/>
                    {state.customerDataValidation.includes('Address.City') &&
                        <ValidationToolTip>Bitte geben Sie eine Stadt an.</ValidationToolTip>
                    }
                </div>
                <div className="relative col-span-6 md:col-span-3 address-form--country">
                    <Select label="Land*" value={state.customerData.Address.Country}
                            onChange={(value) => {
                                updateCustomerData('Address.Country', value);
                                state.setCustomerCountry(value);

                                if (value === 'DE') {
                                    state.setCustomerData((customerData) => {
                                        customerData.VatId = '';
                                        return customerData;
                                    })
                                }
                            }}
                            menuProps={{tabIndex: 0}}>
                        {countries.map((entry) => {
                            return <Option key={entry.code} value={entry.code} disabled={entry.code === '--'}>{entry.label}</Option>
                        })}
                    </Select>
                </div>
            </div>
            {state.customerData.Address.Country !== 'DE' &&
                <div className="relative">
                    <Input label="Umsatzsteuer-ID" placeholder={getVatIdPlaceholder(state.customerData.Address.Country)} {...makeProps('VatId')}/>
                    {state.customerDataValidation.includes('VatId') &&
                        <ValidationToolTip>Bitte geben Sie eine gültige Umsatzsteuer-ID ein.</ValidationToolTip>
                    }
                </div>
            }
        </div>
    </>
}
