import {useState} from "react";

export default function (props) {
    const [open, setOpen] = useState(props.open ?? true);
    const [opened, setOpened] = useState(open);

    const toggleOpen = () => {
        let toggledState = !open;

        setOpen(toggledState);

        if (!toggledState) {
            setOpened(toggledState);
        } else {
            setTimeout(() => setOpened(toggledState), 300);
        }
    };

    return <div className="mb-20">
        <div className={(open ? `` : `feature-section--title--open `) + `feature-section--title`} onClick={toggleOpen}>
            {props.title}
        </div>
        <div className={(open ? `feature-section--content--open ` : `feature-section--content--close `) + (opened ? `overflow-visible ` : `overflow-hidden `) + `feature-section--content`}>
            {props.children}
        </div>
    </div>
}
