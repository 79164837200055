import {electronicFormatIBAN, validateBIC, validateIBAN} from "ibantools";
import {finalizePaymentData} from "../Billwerk/SubscriptionJS";

let validVatIds = {};

export default async function validateCustomerData(customerData) {
    let errors = []

    const field = s => s ?? '';

    if (field(customerData.EmailAddress).length < 1) {
        errors.push('EmailAddress');
    } else {
        let emailInput = document.createElement('input');
        emailInput.setAttribute('type', 'email');
        emailInput.value = customerData.EmailAddress;

        if (!emailInput.checkValidity()) {
            errors.push('EmailAddress');
        }
    }

    if (field(customerData.FirstName).length < 1) {
        errors.push('FirstName');
    }

    if (field(customerData.LastName).length < 1) {
        errors.push('LastName');
    }

    if (field(customerData.CompanyName).length < 1) {
        errors.push('CompanyName');
    }

    if (field(customerData.Address?.Street).length < 1) {
        errors.push('Address.Street');
    }

    if (field(customerData.Address?.HouseNumber).length  < 1) {
        errors.push('Address.HouseNumber');
    }

    if (field(customerData.Address?.PostalCode).length < 4 || field(customerData.Address?.PostalCode).length > 5 || !/^[0-9]{4,5}$/.test(field(customerData.Address?.PostalCode))) {
        errors.push('Address.PostalCode');
    }

    if (field(customerData.Address?.City).length < 1) {
        errors.push('Address.City');
    }

    if (field(customerData.Address?.Country).length < 1) {
        errors.push('Address.Country');
    } else if (field(customerData.Address?.Country) !== 'DE') {
        if (field(customerData.VatId).length < 1) {
            errors.push('VatId');
        } else {
            let isVatIdValid = validVatIds[field(customerData.VatId)] || await fetch('/api/check_vat/' + field(customerData.VatId))
                .then(response => response.json())
                .then(json => json.is_valid)

            if (!isVatIdValid) {
                errors.push('VatId');
            } else {
                validVatIds[field(customerData.VatId)] = true;
            }
        }
    }

    return errors;
}

export function validateSepaPaymentData(sepaPaymentData, reportEmpty = false) {
    let errors = []

    if (reportEmpty && sepaPaymentData.accountHolder?.length < 1) {
        errors.push('accountHolder');
    }

    if (reportEmpty && sepaPaymentData.iban?.length < 1) {
        errors.push('iban');
    } else if (sepaPaymentData.iban?.length > 0) {
        let iban = electronicFormatIBAN(sepaPaymentData.iban);

        if (!validateIBAN(iban).valid) {
            errors.push('iban');
        }
    }

    if (reportEmpty && sepaPaymentData.bic?.length < 1) {
        errors.push('bic');
    } else if (sepaPaymentData.bic?.length > 0) {
        if (!validateBIC(sepaPaymentData.bic).valid) {
            errors.push('bic');
        }
    }

    return errors;
}

export function validateCreditCardPaymentData(paymentData, reportEmpty = false) {
    let errors = []

    if (reportEmpty && paymentData.cardHolder?.length < 1) {
        errors.push('cardHolder');
    }

    if (reportEmpty && paymentData.cardNumber?.length < 1) {
        errors.push('cardNumber');
    } else if (paymentData.cardNumber?.length > 0 && !/^[0-9]+[0-9 ]+$/.test(paymentData.cardNumber)) {
        errors.push('cardNumber');
    }

    if (reportEmpty && paymentData.expiryMonth?.length < 1) {
        errors.push('expiryMonth');
    } else if (paymentData.expiryMonth?.length > 0 && !/^[0-9]{2}$/.test(paymentData.expiryMonth) || parseInt(paymentData.expiryMonth) > 12) {
        errors.push('expiryMonth');
    }

    if (reportEmpty && paymentData.expiryYear?.length < 1) {
        errors.push('expiryYear');
    } else if (paymentData.expiryYear?.length > 0) {
        if (!/^[0-9]{2}$/.test(paymentData.expiryYear)) {
            errors.push('expiryYear');
        } else {
            let finalizedPaymentData = finalizePaymentData(paymentData);

            if (finalizedPaymentData.expiryYear && finalizedPaymentData.expiryYear < (new Date()).getFullYear()) {
                errors.push('expiryYear');
            }
        }
    }

    if (reportEmpty && paymentData.cvc?.length < 1) {
        errors.push('cvc');
    } else if (paymentData.cvc?.length > 0 && !/^[0-9]+$/.test(paymentData.cvc)) {
        errors.push('cvc');
    }

    return errors;
}
