import NextStepButton from "./NextStepButton";
import {useContext, useState} from "react";
import {StateContext} from "../Contexts";
import {
    PAYMENT_OPTION_SEPA,
    PAYMENT_OPTION_SEPA_XML,
    PERIOD_ANNUALLY,
    STEP_ADDRESS,
    STEP_PAYMENT,
    STEP_PLAN
} from "../Constants";
import PlanLineItem from "./PlanLineItem";
import Line from "@erecht24/frontend-kit/theme/components/misc/Line";
import Coupon from "./Coupon";
import ComponentLines from "./ComponentLines";
import formatCurrency from "../Util/formatCurrency";
import {getLabelForCountryCode} from "../Util/countries";
import {getLongDate, getShortDate} from "../Util/formatDate";
import BreadcrumbsMobile from "./BreadcrumbsMobile";
import ChevronDown from "../../../img/chevron-down_2.svg";
import {
    getCurrentPlanVariant,
    getMonthlyPriceForPlanVariant,
    getPlanVariantForPeriod
} from "../Billwerk/SubscriptionJS";
import useAnnualPlanMode from "../Util/useAnnualPlanMode";

export default function () {
    /** @type CheckoutState */
    const state = useContext(StateContext);
    const [collapsed, setCollapsed] = useState(true);

    if (!state.cartViewData) {
        return <></>
    }

    sessionStorage?.setItem('checkout_cart', JSON.stringify(state.cart));
    sessionStorage?.setItem('checkout_cart_view_data', JSON.stringify(state.cartViewData));

    let currentPlanVariant = getCurrentPlanVariant(state);
    let totalNet = formatCurrency(state.cartViewData.totalNet);
    let totalNetBeforeDiscount = null;
    let couponDiscount = null;
    let totalVat = formatCurrency(state.cartViewData.totalVat);
    let totalGross = formatCurrency(state.cartViewData.totalGross);
    let nextTotalGross = state.cartViewData.nextTotalGross ? formatCurrency(state.cartViewData.nextTotalGross) : null;
    let productTotalNet = formatCurrency(state.cartViewData.plan.price);

    if (state.cartViewData.couponCode && state.cartViewData.couponAppliesToCart) {
        totalNetBeforeDiscount = formatCurrency(state.cartViewData.totalNetBeforeDiscount);
        couponDiscount = formatCurrency(state.cartViewData.discountNet * -1);
    }

    let hasCreditNotes = false;

    if (state.cartViewData.components.filter((entry) => entry.price < 0).length > 0) {
        hasCreditNotes = true;
    }

    let strikethroughPrice = null;
    let altPriceHint = null;

    if (currentPlanVariant?.strikethrough_price) {
        if (currentPlanVariant?.fee_period_unit === 'Year') {
            strikethroughPrice = formatCurrency(currentPlanVariant?.strikethrough_price);
        } else if (currentPlanVariant?.fee_period_unit === 'Month') {
            strikethroughPrice = formatCurrency(currentPlanVariant?.strikethrough_price / 12.);
        }
    }

    if (currentPlanVariant?.contract_period_unit === 'Month' && state.mainProps.checkoutVersion === 'b') {
        altPriceHint = 'im Jahrestarif mit mtl. Zahlung ' + formatCurrency(getMonthlyPriceForPlanVariant(getPlanVariantForPeriod(state, PERIOD_ANNUALLY)));
    }

    let includedComponents = state.cartViewData.includedComponents.filter((entry) => entry.includedInPlan);
    let addonComponents = state.cartViewData.components.filter((entry) => entry.price >= 0);

    let vatLines = Object.entries(state.cartViewData?.vat ?? {}).filter((entry) => {
        const [vatPercentage, amount] = entry;

        return amount > 0;
    })

    return <div className="cart">
        <div className="cart--summary-section">
            <div className="cart--breadcrumbs">
                <BreadcrumbsMobile></BreadcrumbsMobile>
            </div>
            <h2 className="cart--heading">Bestellübersicht</h2>
            <div className={(collapsed ? `collapsed ` : ``) + `cart--plan flex flex-col gap-6 lg:gap-10`}>
                <PlanLineItem plan={state.cartViewData.plan}
                              strikethroughPrice={currentPlanVariant?.strikethrough_price ?? null}
                ></PlanLineItem>
                {(includedComponents.length > 0 || addonComponents.length > 0) &&
                    <div>
                        <ComponentLines
                            components={includedComponents}
                        ></ComponentLines>
                        <ComponentLines
                            components={addonComponents}
                        ></ComponentLines>
                    </div>
                }
                <p className="cart--change-plan">
                    <button onClick={() => state.setStep(STEP_PLAN)}
                            className="text-blue underline font-bold hover:text-gray-very-dark">
                        Tarif anpassen
                    </button>
                </p>
            </div>
            {(state.step === STEP_ADDRESS || state.step === STEP_PAYMENT) && (
                <>
                    <div className="cart--address">
                        <Line className="mt-5 mb-10"></Line>
                        <h3 className="text-2xl text-blue-dark font-bold mb-6">Rechnungsanschrift</h3>
                        <div className="grid gap-4 text-graphite">
                            <p>
                                Name
                                <span className="block font-bold mt-1">
                                    {state.customerData.FirstName} {state.customerData.LastName}
                                </span>
                            </p>
                            <p>
                                E-Mail
                                <span className="block font-bold mt-1">
                                    {state.customerData.EmailAddress}
                                </span>
                            </p>
                            <p>
                            Anschrift
                                <span className="block font-bold mt-1">
                                    {state.customerData.CompanyName}<br/>
                                    {state.customerData.Address.Street} {state.customerData.Address.HouseNumber}<br/>
                                    {state.customerData.Address.PostalCode} {state.customerData.Address.City}<br/>
                                    {getLabelForCountryCode(state.countries, state.customerData.Address.Country)}
                                </span>
                            </p>
                            {state.customerData.VatId &&
                                <p>
                                    Umsatzsteuer-ID
                                    <span className="block font-bold mt-1">
                                        {state.customerData.VatId}
                                    </span>
                                </p>
                            }
                        </div>
                        <p className="mt-6 mb-5">
                            <button onClick={() => state.setStep(STEP_ADDRESS, true, STEP_ADDRESS)}
                                    className="text-blue underline font-bold hover:text-gray-very-dark">
                                Rechnungsanschrift ändern
                            </button>
                        </p>
                        {state.paymentOption &&
                            <>
                                <Line className="mb-10"></Line>
                                <h3 className="text-2xl text-blue-dark font-bold mb-6">Zahlungsart</h3>
                                <p>
                                    {state.paymentOption.label}
                                    {(state.paymentOption.key === PAYMENT_OPTION_SEPA.key || state.paymentOption.key === PAYMENT_OPTION_SEPA_XML.key) && (
                                        <>
                                            <br/>
                                            Kontoinhaber: {state.paymentData.accountHolder}<br/>
                                            IBAN: {state.paymentData.iban}<br/>
                                            BIC: {state.paymentData.bic}
                                        </>
                                    )}
                                </p>
                                <p className="mt-6 mb-5">
                                    <button onClick={() => state.setStep(STEP_ADDRESS, true, STEP_PAYMENT)}
                                            className="text-blue underline font-bold hover:text-gray-very-dark">
                                        Zahlungsart ändern
                                    </button>
                                </p>
                            </>
                        }
                    </div>
                </>
            )}
            {hasCreditNotes &&
                <>
                    <Line></Line>
                    <h3 className="text-2xl text-blue-dark font-bold mb-3 mt-10">Gutschrift aus Ihrem aktuellen Vertrag</h3>
                    <ComponentLines
                        components={state.cartViewData.components.filter((entry) => entry.price < 0)}
                        className="mb-10"
                    ></ComponentLines>
                </>
            }
            {(state.step === STEP_ADDRESS || state.step === STEP_PAYMENT || useAnnualPlanMode(state)) && (
                <>
                    <Line></Line>
                    {state.mainProps.showVoucher &&
                        <Coupon className="mt-10"></Coupon>
                    }
                </>
            )}
            <div className={(collapsed ? `collapsed ` : ``) + `cart--calculation`}>
                <div className="flex flex-row justify-between text-xl text-graphite font-bold">
                    <span>Zwischensumme:</span>
                    <span className="text-right">{totalNetBeforeDiscount ?? totalNet}</span>
                </div>
                {state.cartViewData.totalNet === currentPlanVariant?.recurring_fee &&
                    <>
                        {strikethroughPrice &&
                            <div className="flex flex-row justify-end text-graphite">
                                <span className={state.mainProps.checkoutVersion === 'b' ? `strikethrough-price-straight-text-color` : `strikethrough-price-straight`}>
                                    statt bei Monatstarif {strikethroughPrice}
                                </span>
                            </div>
                        }
                        {altPriceHint &&
                            <div className="flex flex-row justify-end">
                                statt {altPriceHint}
                            </div>
                        }
                    </>
                }
                {couponDiscount &&
                    <>
                        <div className="flex flex-row justify-between text-xl text-green font-bold">
                            <span>Gutschein:</span>
                            <span className="text-right">{couponDiscount}</span>
                        </div>
                        <div className="flex flex-row justify-between text-xl text-graphite font-bold">
                            <span>Summe:</span>
                            <span className="text-right">{totalNet}</span>
                        </div>
                    </>
                }
                {vatLines.length > 0 && vatLines.map((entry) => {
                    const [vatPercentage, amount] = entry;

                    return <div className="flex flex-row justify-between text-xl text-graphite">
                        <span>MwSt. ({vatPercentage}%):</span>
                        <span className="text-right">{formatCurrency(amount)}</span>
                    </div>
                })}
                {vatLines.length < 1 &&
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row justify-between text-xl text-graphite">
                            <span>MwSt. (0%):</span>
                            <span className="text-right">{formatCurrency(0)}</span>
                        </div>
                        <p className="text-sm">
                            Die Bestellung enthält keine Umsatzsteuer, da die Steuerschuld beim Leistungsempfänger liegt (Reverse-Charge-Verfahren).
                        </p>
                    </div>
                }
            </div>
            {state.cartViewData?.cartType === 'downgrade_purchase' &&
                <div
                    className={((state.step !== STEP_PAYMENT && collapsed) ? 'hidden lg:block ' : '') + 'relative text-xl font-regular px-3 py-3 rounded-sm border text-graphite bg-background-blue border-[#a9daf3] my-10 flex'}>
                    Ihr neuer Vertrag mit uns startet nach dem Auslaufen Ihres aktuellen Vertrags zum {getShortDate(state.cartViewData?.changeDate)}.
                </div>
            }
        </div>
        <div className="cart--bottom-line">
            <Line className={collapsed ? 'hidden lg:block lg:mt-10' : 'block mt-10'}></Line>
            <div className={(collapsed ? 'mt-0 lg:mt-10' : 'mt-10') + ` flex flex-row justify-between`}>
                {state.cartViewData && (
                    <>
                        {state.cartViewData?.cartType === 'downgrade_purchase' &&
                            <>
                                <h3 className="h3">
                                    Ab {getShortDate(state.cartViewData?.changeDate)}
                                    {state.cartViewData.plan.feePeriodUnit === 'Year'
                                            ? ` jährlich `
                                            : (state.cartViewData.plan.feePeriodUnit === 'Month' && state.cartViewData.plan.feePeriodQuantity === 1
                                                ? ` monatlich `
                                                : ` `)}
                                    zu zahlen
                                </h3>
                                <span className="h3">{totalGross}</span>
                            </>
                        }
                        {state.cartViewData?.cartType !== 'downgrade_purchase' &&
                            <>
                                <h3 className="h3">
                                    {state.contractData &&
                                        <>
                                            {state.cartViewData.totalGross > 0 ? 'Zu zahlen' : 'Gutschrift'}
                                        </>
                                    }
                                    {!state.contractData &&
                                        <>
                                            {state.cartViewData.plan.feePeriodUnit === 'Year'
                                                ? `Ihr Jahresbeitrag`
                                                : (state.cartViewData.plan.feePeriodUnit === 'Month' && state.cartViewData.plan.feePeriodQuantity === 1
                                                    ? `Ihr Monatsbeitrag`
                                                    : `Ihr Beitrag`)}
                                        </>
                                    }
                                </h3>
                                <span className="h3">{totalGross}</span>
                            </>
                        }
                    </>
                )}
                {!state.cartViewData && (
                    <>
                        <h3 className="h3 h-[30px] w-1/3 skeleton"></h3>
                        <span className="h3 h-[30px] w-20 skeleton"></span>
                    </>
                )}
            </div>
            {state.cartViewData && state.cartViewData?.cartType !== 'downgrade_purchase' && (
                <div className={(collapsed ? `collapsed ` : ``) + `cart--next-charge-date`}>
                    <span>Nächste Abrechnung: {getLongDate(state.cartViewData.nextTotalGrossDate)}</span>
                    {nextTotalGross && nextTotalGross !== totalGross &&
                        <span className="font-bold ml-auto">{nextTotalGross}</span>
                    }
                </div>
            )}
            <div className="mt-10">
                <button onClick={() => setCollapsed(!collapsed)}
                        className={`cart--collapse-toggle` + (!collapsed ? ' cart--collapse-toggle--expanded' : '')}>
                    Bestelldetails {collapsed ? `anzeigen` : `ausblenden`}
                    <img src={ChevronDown} alt=""/>
                </button>
                <NextStepButton></NextStepButton>
            </div>
        </div>
    </div>
}
