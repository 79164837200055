import Dialog from "@erecht24/frontend-kit/theme/components/dialogs/Dialog";
import DialogBody from "@erecht24/frontend-kit/theme/components/dialogs/DialogBody";
import AgencyPricingTable from "./AgencyPricingTable";
import WebsitePricingTable from "./WebsitePricingTable";
import ShopPricingTable from "./ShopPricingTable";
import {useContext, useState} from "react";
import {StateContext} from "../Checkout/Contexts";
import DialogHeader from "@erecht24/frontend-kit/theme/components/dialogs/DialogHeader";
import {SVGCross} from "@erecht24/frontend-kit/theme/components/misc/SVG";
import {
    addToCart,
    getDiscount,
    getMonthlyPriceForPlanVariant,
    getPlanVariantByExternalId, getPlanVariantPeriod, getSpecialOfferSuffix
} from "../Checkout/Billwerk/SubscriptionJS";
import {PERIOD_ANNUALLY, PERIOD_MONTHLY} from "../Checkout/Constants";
import ButtonSecondary from "@erecht24/frontend-kit/theme/components/buttons/ButtonSecondary";
import Select from "@erecht24/frontend-kit/theme/components/selects/Select";
import Option from "@erecht24/frontend-kit/theme/components/selects/Option";
import formatCurrency from "../Checkout/Util/formatCurrency";

/**
 *
 * @param {string} props.selectedCategory
 * @param {boolean} props.forceSelection
 * @returns {JSX.Element}
 */
export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);
    const [selectedCategory, setSelectedCategory] = useState(props.selectedCategory ?? 'agency');

    const specialOfferSuffix = getSpecialOfferSuffix(state.mainProps.variantLocked ?? state.mainProps.product);
    const handleOpen = () => state.setPlanDialogOpen(!state.planDialogOpen);

    /**
     * @param {App_Http_Resources_PlanVariant} planVariant
     */
    const getDefaultPlanVariant = (planVariant) => {
        if (!planVariant.plan.default_variant) {
            return null;
        }

        return state.productInfo.plan_variants[planVariant.plan.default_variant] ?? null;
    }

    /**
     * @param {App_Http_Resources_PlanVariant} planVariant
     */
    const updatePeriod = (planVariant) => {
        let period = getPlanVariantPeriod(planVariant);

        if (period !== null) {
            state.setPeriod(period);
        }
    }

    /**
     * @param {App_Http_Resources_PlanVariant }planVariant
     */
    const updateState = (planVariant) => {
        let defaultPlanVariant = getDefaultPlanVariant(planVariant);

        updatePeriod(defaultPlanVariant ?? planVariant);
        state.setCart(cart => addToCart(state, cart, {
            PlanVariantId: planVariant.id,
            components: {}
        }));
        handleOpen();
    };

    /**
     *
     * @param {string|null} props.selectText
     * @param {string|null} props.selectShortText
     * @param {string|null} props.selectedText
     * @param {App_Http_Resources_PlanVariant} props.planVariant
     * @returns {JSX.Element}
     * @constructor
     */
    const SelectPlanControl = (props) =>
        <>
            {props.planVariant?.id === state.cart?.PlanVariantId && <>
                <div className="block md:hidden text-blue w-full font-black text-md uppercase">
                    {props.selectedText ?? 'ausgewählt'}
                </div>
                <div
                    className="hidden md:block align-middle font-sans font-black text-center uppercase text-sm 2xl:text-lg py-3 px-2 lg:px-6 rounded-sm border-2 border-solid leading-[19px] bg-transparent border-button-blue text-button-blue w-full">
                    {props.selectedText ?? 'ausgewählt'}
                </div>
            </>

            }
            {props.planVariant?.id !== state.cart?.PlanVariantId && <>
                <div className="block md:hidden text-blue w-full font-black text-md underline uppercase">
                {props.selectShortText ?? props.selectText ?? 'absichern'}
                </div>
                <ButtonSecondary
                    className="hidden md:block w-full text-sm 2xl:text-lg px-2 lg:px-6"
                    onClick={() => updateState(props.planVariant)}>
                    { props.selectText ?? 'Jetzt absichern' }
                </ButtonSecondary>
            </>}
        </>

    let planVariantBasic = getPlanVariantByExternalId(state,selectedCategory + '-basic-' + state.period + ((state.period === PERIOD_ANNUALLY) ? (specialOfferSuffix ?? '') : ''));
    let planVariantBasicAnnually = getPlanVariantByExternalId(state,selectedCategory + '-basic-' + PERIOD_ANNUALLY + (specialOfferSuffix ?? ''));
    let planVariantBasicMonthly = getPlanVariantByExternalId(state,selectedCategory + '-basic-' + PERIOD_MONTHLY);
    let planVariantBusiness = getPlanVariantByExternalId(state, selectedCategory + '-business-' + state.period + ((state.period === PERIOD_ANNUALLY) ? (specialOfferSuffix ?? '') : ''));
    let planVariantBusinessAnnually = getPlanVariantByExternalId(state, selectedCategory + '-business-' + PERIOD_ANNUALLY + (specialOfferSuffix ?? ''));
    let planVariantBusinessMonthly = getPlanVariantByExternalId(state, selectedCategory + '-business-' + PERIOD_MONTHLY);
    let planVariantEnterprise = getPlanVariantByExternalId(state, selectedCategory + '-enterprise-' + state.period + ((state.period === PERIOD_ANNUALLY) ? (specialOfferSuffix ?? '') : ''));
    let planVariantEnterpriseAnnually = getPlanVariantByExternalId(state, selectedCategory + '-enterprise-' + PERIOD_ANNUALLY + (specialOfferSuffix ?? ''));
    let planVariantEnterpriseMonthly = getPlanVariantByExternalId(state, selectedCategory + '-enterprise-' + PERIOD_MONTHLY);


return <Dialog open={state.planDialogOpen} dismiss={{enabled: !props.forceSelection}} handler={handleOpen} size="xl">
        <DialogHeader>
            {!props.forceSelection && <div className="flex gap-2 justify-end">
                <div className="flex-initial cursor-pointer pt-6 pr-6"
                     onClick={handleOpen}>
                    <SVGCross/>
                </div>
            </div>}
        </DialogHeader>
        <DialogBody className="px-2 mt-2 max-h-[90dvh] overflow-y-scroll">
            <div
                className="flex flex-row flex-wrap sticky top-0 z-[9999999] bg-white border-b-2 border-b-[#eeeeee] pb-4 mb-6">
                <div
                    className="w-full lg:w-1/4 text-lg text-[#056688] flex justify-center lg:justify-start py-4 lg-py-2 xl:pl-8">
                    <div className="hidden lg:block">
                        <p className="mb-4">Wählen Sie Ihren Bereich aus:</p>
                        <div className={(selectedCategory === 'website' ? 'font-bold' : '') + ` uppercase`}>
                            <label>
                                <input
                                    type="radio"
                                    name="category"
                                    value="website"
                                    checked={selectedCategory === 'website'}
                                    onChange={(event) => setSelectedCategory(event.target.value)}
                                    className="mr-2"
                                />
                                Webseitenbetreiber
                            </label>
                        </div>
                        <div
                            className={(selectedCategory === 'agency' ? 'font-bold' : '') + ` uppercase`}>
                            <label>
                                <input
                                    type="radio"
                                    name="category"
                                    value="agency"
                                    checked={selectedCategory === 'agency'}
                                    onChange={(event) => setSelectedCategory(event.target.value)}
                                    className="mr-2"
                                />
                                Agenturen
                            </label>
                        </div>
                        <div
                            className={(selectedCategory === 'shop' ? 'font-bold' : '') + ` uppercase`}>
                            <label>
                                <input
                                    type="radio"
                                    name="category"
                                    value="shop"
                                    checked={selectedCategory === 'shop'}
                                    onChange={(event) => setSelectedCategory(event.target.value)}
                                    className="mr-2"
                                />
                                Shopbetreiber
                            </label>
                        </div>
                    </div>
                    <div className="block lg:hidden w-full">
                        <Select label="Bitte Bereich wählen" value={selectedCategory}
                                onChange={(value) => {
                                    setSelectedCategory(value);
                                }}>
                            <Option value="website">Webseitenbetreiber</Option>
                            <Option value="shop">Shopbetreiber</Option>
                            <Option value="agency">Agenturen</Option>
                        </Select>
                    </div>
                </div>
                <div onClick={() => updateState(planVariantBasic)}
                     className={(planVariantBasic.id === state.cart?.PlanVariantId ? 'bg-blue/20 md:bg-primary text-primary md:text-white' : 'bg-background-blue text-primary') + ` w-1/3 lg:w-1/4 text-center flex flex-col items-end p-2 lg:p-[25px] shadow-[0px_2px_10px_rgba(0,0,0,0.15)]`}>
                    <div className="w-full">
                        <div
                            className="flex flex-col 2xl:flex-row text-[1.875rem] leading-[1.875rem] font-bold justify-between w-full mb-2 md:mb-[25px]">
                            <div className="text-xl lg:text-[1.5625rem]">Basic</div>
                            <div className="flex flex-row justify-center 2xl:justify-between">
                                {state.period === PERIOD_ANNUALLY &&
                                    <span className="strikethrough-price">
                                        {getMonthlyPriceForPlanVariant(planVariantBasicMonthly)}
                                    </span>
                                }
                                <span className="">{getMonthlyPriceForPlanVariant(planVariantBasic)}</span>
                                <div className="flex flex-col items-end">
                                    <span className="text-lg leading-[19px]">€</span>
                                    <span className="text-[0.5625rem] leading-[9px] font-normal">mtl.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SelectPlanControl planVariant={planVariantBasic}/>
                </div>
                <div onClick={() => updateState(planVariantBusiness)}
                    className={(planVariantBusiness.id === state.cart?.PlanVariantId ? 'bg-blue/20 md:bg-primary text-primary md:text-white' : 'bg-background-blue text-primary') + ` w-1/3 lg:w-1/4 text-center flex flex-col items-end p-2 lg:p-[25px] shadow-[0px_2px_10px_rgba(0,0,0,0.15)]`}>
                    <div className="w-full">
                        <div
                            className="flex flex-col 2xl:flex-row text-[1.875rem] leading-[1.875rem] font-bold justify-between w-full mb-2 md:mb-[25px]">
                            <div className="text-xl lg:text-[1.5625rem]">Business</div>
                            <div className="flex flex-row justify-center 2xl:justify-between">
                                {state.period === PERIOD_ANNUALLY &&
                                    <span className="strikethrough-price">
                                        {getMonthlyPriceForPlanVariant(planVariantBusinessMonthly)}
                                    </span>
                                }
                                <span className="">{formatCurrency(getMonthlyPriceForPlanVariant(planVariantBusiness), true, true)}</span>
                                <div className="flex flex-col items-end">
                                    <span className="text-lg leading-[19px]">€</span>
                                    <span className="text-[0.5625rem] leading-[9px] font-normal">mtl.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SelectPlanControl planVariant={planVariantBusiness}/>
                </div>
                <div onClick={() => updateState(planVariantEnterprise)}
                    className={(planVariantEnterprise.id === state.cart?.PlanVariantId ? 'bg-blue/20 md:bg-primary text-primary md:text-white' : 'bg-background-blue text-primary') + ` w-1/3 lg:w-1/4 text-center flex flex-col items-end p-2 lg:p-[25px] shadow-[0px_2px_10px_rgba(0,0,0,0.15)]`}>
                    <div className="w-full">
                        <div
                            className="flex flex-col 2xl:flex-row text-[1.875rem] leading-[1.875rem] font-bold justify-between w-full mb-2 md:mb-[25px]">
                            <div className="text-xl lg:text-[1.5625rem]">Enterprise</div>
                            <div className="flex flex-row justify-center 2xl:justify-between">
                                {state.period === PERIOD_ANNUALLY &&
                                    <span className="strikethrough-price">
                                        {getMonthlyPriceForPlanVariant(planVariantEnterpriseMonthly)}
                                    </span>
                                }
                                <span className="">{formatCurrency(getMonthlyPriceForPlanVariant(planVariantEnterprise), true, true)}</span>
                                <div className="flex flex-col items-end">
                                    <span className="text-lg leading-[19px]">€</span>
                                    <span className="text-[0.5625rem] leading-[9px] font-normal">mtl.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SelectPlanControl planVariant={planVariantEnterprise}/>
                </div>
            </div>
            {selectedCategory === 'website' &&
                <WebsitePricingTable
                    basicDiscount={getDiscount(planVariantBasicAnnually, planVariantBasicMonthly)}
                    businessDiscount={getDiscount(planVariantBusinessAnnually, planVariantBusinessMonthly)}
                    enterpriseDiscount={getDiscount(planVariantEnterpriseAnnually, planVariantEnterpriseMonthly)}
                ></WebsitePricingTable>
            }
            {selectedCategory === 'shop' &&
                <ShopPricingTable
                    basicDiscount={getDiscount(planVariantBasicAnnually, planVariantBasicMonthly)}
                    businessDiscount={getDiscount(planVariantBusinessAnnually, planVariantBusinessMonthly)}
                    enterpriseDiscount={getDiscount(planVariantEnterpriseAnnually, planVariantEnterpriseMonthly)}
                ></ShopPricingTable>
            }
            {selectedCategory === 'agency' &&
                <AgencyPricingTable
                    basicDiscount={getDiscount(planVariantBasicAnnually, planVariantBasicMonthly)}
                    businessDiscount={getDiscount(planVariantBusinessAnnually, planVariantBusinessMonthly)}
                    enterpriseDiscount={getDiscount(planVariantEnterpriseAnnually, planVariantEnterpriseMonthly)}
                ></AgencyPricingTable>
            }
        </DialogBody>
    </Dialog>
}
