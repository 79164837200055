import Dialog from "@erecht24/frontend-kit/theme/components/dialogs/Dialog";
import DialogBody from "@erecht24/frontend-kit/theme/components/dialogs/DialogBody";
import DialogHeader from "@erecht24/frontend-kit/theme/components/dialogs/DialogHeader";
import {SVGCross} from "@erecht24/frontend-kit/theme/components/misc/SVG";
import {useState} from "react";
import Spinner from "@erecht24/frontend-kit/theme/components/misc/Spinner";

export default function (props) {
    const [html, setHtml] = useState(null);

    if (props.open && !html) {
        fetch('/legal/privacy-policy')
            .then(response => response)
            .then(response => response.text())
            .then(text => setHtml(text))
    }

    return <Dialog open={props.open} handler={props.handleOpen} size="xl">
        <DialogHeader>
            <div className="flex gap-2 justify-end">
                <div className="flex-initial cursor-pointer pt-6 pr-6"
                     onClick={props.handleOpen}>
                    <SVGCross/>
                </div>
            </div>
            <h3 className="h3 !text-3xl mb-10 px-8">Datenschutzerklärung von eRecht24</h3>
        </DialogHeader>
        <DialogBody className="px-8 pb-8 md:pb-20 max-h-[70dvh] overflow-scroll legal-dialog">
            <a id="privacy-policy-dialog-body" href="#" className="focus:outline-0 h-0">&nbsp;</a>
            {html &&
                <section className="article-content" itemProp="articleBody" dangerouslySetInnerHTML={{__html: html}}></section>
            }
            {!html &&
                <div className="flex flex-col items-center">
                    <Spinner className="w-[124px] h-[124px] mb-6"></Spinner>
                    <p>Die Datenschutzerklärung wird geladen.</p>
                </div>
            }
        </DialogBody>
    </Dialog>
}
