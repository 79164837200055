import {track} from "../Util/tracking";
import {useContext} from "react";
import {StateContext} from "../Contexts";
import cancellationHint from "../Util/cancellationHint";
import {getCurrentPlanVariant} from "../Billwerk/SubscriptionJS";

export default function (props) {
    const state = useContext(StateContext);
    let planVariant = getCurrentPlanVariant(state);

    return <>
        <div className={`flex flex-col gap-3 mb-10 text-graphite ` + props.className}>
            <p>
                Es gelten unsere <a onClick={() => track(
                    () => state.setAgbOpen(true),
                    'checkout',
                    'cart_agb',
                    'cart_agb'
                )} className="font-bold text-blue hover:text-gray-very-dark hover:underline hover:cursor-pointer">AGB</a>.
                {' ' + cancellationHint(planVariant?.cancellation_period_quantity ?? 0, planVariant?.cancellation_period_unit ?? 'Day') + ' '}
                Informationen zum Datenschutz finden Sie in unserer <a onClick={() => track(
                    () => state.setPrivacyPolicyOpen(true),
                    'checkout',
                    'cart_privacy_policy',
                    'cart_privacy_policy'
                )} className="font-bold text-blue hover:text-gray-very-dark hover:underline hover:cursor-pointer">Datenschutzerklärung</a>.
            </p>
            <p>
                Wir nutzen Ihre E-Mail-Adresse, um Ihnen regelmäßig Updates und Informationen über ähnliche Dienstleistungen zuzusenden.
                Sie können jederzeit kostenlos widersprechen, z.&nbsp;B. über den Abmeldelink in den E-Mails.
            </p>
        </div>
    </>
}
