import {useContext} from "react";
import {StateContext} from "../Contexts";
import BreadcrumbsDesktop from "./BreadcrumbsDesktop";
import BreadcrumbsMobile from "./BreadcrumbsMobile";

export default function (props) {
    /** @type CheckoutState */
    const state = useContext(StateContext);

    const goto = (target) => {
        if (state.step === target) return;
        state.setStep(target);
    };

    return <div>
        <BreadcrumbsDesktop></BreadcrumbsDesktop>
        <BreadcrumbsMobile></BreadcrumbsMobile>
    </div>
}
